<template>
  <div class="yb-view">
    <div class="yb-view-header relative flex flex-col space-y-4 pb-4">
      <div class="flex flex-row flex-nowrap items-center space-x-2">
        <div class="h-6 w-6">
          <yb-icon icon="instance" />
        </div>
        <div class="flex items-center justify-between w-full">
          <div class="text-lg font-semibold select-none">
            Instance:  {{ instanceName }}
          </div>

          <div v-if="!isMissing" class="flex items-center space-x-2">
            <yb-processing v-if="!historical && !isLoading && isPopulating" class="h-6 w-6" />

            <yb-instance-action-link v-if="!historical && !isLoading" icon="refresh" class="p-4" @click="populate">
              Refresh
            </yb-instance-action-link>

            <yb-drop-down-button
              label="Actions"
              icon="action"
              max-height="h-auto"
              action-link
              class="mr-4"
              placement="bottom-end"
            >
              <yb-drop-down-item label="Open in Query Editor" @click="openInQueryEditor()" />
              <yb-drop-down-item label="Open Fullscreen" @click="toggleFullscreen()" />
              <!--
              <hr>
              <yb-drop-down-item label="Export HTML" />
              <yb-drop-down-item label="Quick Rule" />
              <yb-drop-down-item label="Analyze" />
              -->
              <hr>
              <yb-drop-down-item v-if="canShare" label="Share" @click="share" />
              <yb-drop-down-item v-yb-clipboard:text="url" label="Copy Link to This Query" clipboard-label="(Query URL)" allow-event />
            </yb-drop-down-button>
          </div>
        </div>
      </div>
    </div>
    <div ref="fullscreen" class="yb-view-content yb-view" :class="{'yb-body-background pt-1' : fullscreen, 'yb-view': !isLoading}" @fullscreenchange="fullscreenChange">
      <div v-if="isLoading" class="yb-view-content flex items-center justify-center h-full">
        <yb-loading />
      </div>

      <div v-else-if="isMissing" class="yb-view-content flex items-center justify-center h-full">
        <span class="text-lg">{{ isMissing }}</span>
      </div>

      <template v-else>
        <div v-if="!isLoading" class="yb-view-header flex flex-row flex-nowrap select-none px-2">
          <div class="statistics-category">
            <div class="statistics-icon">
              <yb-svg-time />
            </div>
            <div class="statistics-stat">
              <div class="statistics-label">
                Total Time
              </div>
              <div class="statistics-datum">
                {{ humanizeMs(selectedQuery.total_ms) }}
              </div>
            </div>
            <div class="statistics-stat">
              <div class="statistics-label">
                Run Time
              </div>
              <div class="statistics-datum">
                {{ humanizeMs(selectedQuery.run_ms) }}
              </div>
            </div>
          </div>
          <div class="statistics-category">
            <div class="statistics-icon">
              <yb-svg-resources />
            </div>
            <div class="statistics-stat">
              <div class="statistics-label">
                I/O
              </div>
              <div class="statistics-datum">
                {{ bytes(selectedQuery.io_total_bytes || 0) }}
              </div>
            </div>
            <div class="statistics-stat">
              <div class="statistics-label">
                Network
              </div>
              <div class="statistics-datum">
                {{ bytes(selectedQuery.io_network_bytes || 0) }}
              </div>
            </div>
          </div>
          <div class="statistics-category">
            <div class="statistics-icon">
              <yb-svg-table />
            </div>
            <div class="statistics-stat">
              <div class="statistics-label">
                Rows
              </div>
              <div class="statistics-datum">
                {{ humanizeNumber(selectedQuery.rows_total, 0) }}
              </div>
            </div>
            <div class="statistics-stat">
              <div class="statistics-label">
                Scanned
              </div>
              <div class="statistics-datum">
                {{ humanizeNumber((selectedQuery.rows_scanned || 0), 0) }}
              </div>
            </div>
          </div>
          <div class="statistics-category">
            <div class="statistics-icon">
              <yb-svg-utilization />
            </div>
            <div class="statistics-stat">
              <div class="statistics-label">
                CPU
              </div>
              <div class="statistics-datum">
                {{ number(selectedQuery.cpu_percent, 1) }}%
              </div>
            </div>
            <div class="statistics-stat">
              <div class="statistics-label">
                Memory
              </div>
              <div class="statistics-datum">
                {{ bytes(selectedQuery.memory_bytes) }}
              </div>
            </div>
          </div>
        </div>

        <tabs v-if="!isLoading" class="yb-view-content yb-view">
          <tab title="Query" class="yb-view relative" data-test-id="query">
            <div class="yb-view-content pb-2 pt-4">
              <yb-query-details-base :query-id-param="activeQueryId" :historical="historical" :instance-id="instanceId" :query-text="queryText" @load-query="activeQueryId = $event, load()" />
            </div>
          </tab>
          <tab title="Statistics" class="yb-view relative" data-test-id="statistics">
            <div class="yb-view-content-auto pb-2 pt-4">
              <yb-query-details-stats :query-id-param="activeQueryId" :historical="historical" :instance-id="instanceId" />
            </div>
          </tab>
          <tab title="Plan" class="yb-view relative" data-test-id="plan">
            <tabs class="yb-view-content vertical mt-4">
              <tab title="Overview" class="yb-view relative" data-test-id="overview">
                <div class="yb-view-content">
                  <yb-query-details-plan :query-id-param="activeQueryId" :historical="historical" :instance-id="instanceId" />
                </div>
              </tab>
              <tab title="Details" class="yb-view relative" data-test-id="details">
                <yb-query-details-grid :query-id-param="activeQueryId" :historical="historical" :instance-id="instanceId" />
              </tab>
              <tab title="Analysis" class="yb-view relative" data-test-id="analysis">
                <div class="yb-view-content">
                  <yb-query-details-analyze :query-id-param="activeQueryId" :historical="historical" :instance-id="instanceId" />
                </div>
              </tab>
              <tab title="Summary" class="overflow-y-auto w-full relative" data-test-id="summary">
                <yb-statistics-summary />
              </tab>
            </tabs>
          </tab>
          <tab title="Rule" class="yb-view relative" data-test-id="rule">
            <div class="yb-view-content pt-2">
              <yb-rule-results-grid :query-id="activeQueryId" :instance-id="instanceId" single-query />
            </div>
          </tab>
          <tab title="Timeline" class="yb-view relative" data-test-id="timeline">
            <div v-if="historical && selectedQuery.num_workers > 0" class="yb-view-content pb-2">
              <yb-query-timeline :query-id-param="activeQueryId" :historical="historical" :instance-id="instanceId" />
            </div>
            <div v-else class="yb-view-content flex items-center justify-center h-full">
              <span class="text-lg">
                <template v-if="historical">
                  The timeline for this query cannot be displayed because this query did not use a compute cluster.
                </template>
                <template v-else>
                  The timeline for this query cannot be displayed until it completes.
                </template>
              </span>
            </div>
          </tab>
        </tabs>
      </template>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify'
import Instance from '@/models/Instance'
import { humanizeMs, bytes, humanizeNumber, number } from '@/filters'
import { NotFound } from '@/services/errors'
import YbQueryDetailsBase from '@/app/instance/QueryDetailsBase.vue'
import YbQueryDetailsGrid from '@/app/instance/QueryDetailsGrid.vue'
import YbQueryDetailsPlan from '@/app/instance/QueryDetailsPlan.vue'
import YbQueryDetailsStats from '@/app/instance/QueryDetailsStats.vue'
import YbQueryDetailsAnalyze from '@/app/instance/QueryDetailsAnalyze.vue'
import YbQueryTimeline from '@/app/instance/QueryTimeline.vue'
import YbRuleResultsGrid from '@/app/wlm/RuleResultsGrid.vue'
import YbStatisticsSummary from '@/app/query/StatisticsSummary.vue'
import YbInstanceActionLink from '@/app/instance/InstanceActionLink.vue'
import YbSvgTable from '@/assets/svg/business-workflow-data-table.svg'
import YbSvgResources from '@/assets/svg/cloud-network-1.svg'
import YbSvgUtilization from '@/assets/svg/microprocessor-computer-chip-flash.svg'
import YbSvgTime from '@/assets/svg/time-stopwatch-empty.svg'

export default {
  components: {
    YbQueryDetailsBase,
    YbQueryDetailsGrid,
    YbQueryDetailsPlan,
    YbQueryDetailsStats,
    YbQueryDetailsAnalyze,
    YbQueryTimeline,
    YbStatisticsSummary,
    YbRuleResultsGrid,
    YbInstanceActionLink,
    YbSvgTable,
    YbSvgTime,
    YbSvgResources,
    YbSvgUtilization
  },
  props: {
    queryId: {
      type: [Number, null],
      required: true
    },
    historical: {
      type: Boolean,
      default: true
    },
    queryText: {
      type: String
    },
    instanceName: {
      type: String,
      required: true
    },
    instanceId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      fullscreen: false,
      isLoading: false,
      isPopulating: false,
      isMissing: null,
      activeQueryId: this.queryId
    }
  },
  computed: {
    instance () {
      return Instance.query().where('id', this.instanceId).first()
    },
    selectedQuery: get('query-details/selectedQuery', false),
    canShare() {
      const { url } = this
      return typeof navigator.canShare === 'function' && navigator.canShare({ url })
    },
    url() {
      const { instanceId, queryId } = this
      return `${window.location.origin}/instances/instance/${instanceId}/activity/query?query_id=${queryId}`
    }
  },
  created() {
    this.load()
  },
  methods: {
    async load() {
      this.isLoading = true
      try {
        await this.$store.dispatch('query-details/invalidate')
        await this.populate()
      } finally {
        this.isLoading = false
      }
    },
    async populate() {
      if (!this.instance) {
        this.isMissing = 'The instance for this query could not be located or you do not have access to it.'
        return
      }
      this.isPopulating = true
      this.isMissing = null
      const missingQuery = 'The information for this query could not be located or you do not have access to it.'
      try {
        await this.$store.dispatch('query-details/populate', {
          instance: this.instance,
          query_id: this.activeQueryId,
          ybSystemDebug: this.$toggles.queryDevStats,
          historical: this.historical,
          force: !this.isLoading
        })
      } catch (e) {
        if (e instanceof NotFound) {
          this.isMissing = missingQuery
        } else {
          console.log('Error retrieving query details', this.queryId)
          console.error(e)
        }
      } finally {
        this.isPopulating = false
        if (!this.selectedQuery || this.selectedQuery.total_ms === 0) {
          this.isMissing = missingQuery
        }
      }
    },
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen
      if (this.fullscreen) {
        this.$refs.fullscreen.requestFullscreen()
      } else {
        document.exitFullscreen()
      }
    },
    openInQueryEditor() {
      // Setup query editor load params.
      const content = this.selectedQuery.query_text
      const database = this.selectedQuery.database_name
      const name = `Query ${this.selectedQuery.query_id}`
      this.$store.set('query-editor/load', { name, content, database, schema: 'PUBLIC' })

      // Go to query editor if not there already.
      if (!this.$route.name !== 'yb-query') {
        this.$router.push({ name: 'yb-query' })
      }
    },
    fullscreenChange() {
      this.fullscreen = document.fullscreenElement === this.$refs.fullscreen
    },
    share() {
      const { url } = this
      navigator.share({ url })
    },

    humanizeMs,
    bytes,
    humanizeNumber,
    number
  }
}
</script>

<style scoped lang="postcss">

.statistics-category {
  @apply flex flex-row w-1/4 border rounded-lg p-0.5 xl:p-1 mr-2 mb-4 h-12 xl:h-16 yb-border-content relative;
}

.statistics-category .yb-text-label .not {
  @apply hidden;
}

.statistics-category .label {
  @apply flex flex-row items-center;
}

.statistics-icon {
  @apply hidden lg:flex lg:flex-row items-center justify-center px-4 border-r yb-border-content;
}

.statistics-icon svg {
  @apply h-8 xl:h-12 w-8 xl:w-12 stroke-0;
}

.statistics-stat:nth-child(2) {
  @apply border-r yb-border-content;
}

.statistics-stat {
  @apply flex flex-col justify-between flex-grow;
}

.statistics-label {
  @apply text-xs xl:text-base font-medium text-center whitespace-nowrap truncate;
}
.statistics-datum {
  @apply text-xs md:text-sm xl:text-base xl:text-lg 2xl:text-2xl font-medium xl:font-semibold text-center whitespace-nowrap truncate;
}

.table-stats {
  @apply table text-sm mb-1 -my-4;
}

.table-label {
  @apply w-full text-right text-xs p-0 align-bottom font-semibold;
  line-height: 0.75;
}

.yb-text-label {
  @apply text-yb-gray-medium dark:text-yb-gray-alt-lightest;
}

.table-datum {
  @apply w-full text-left w-1/6 p-0 pl-1 pt-2 align-bottom text-xl leading-3 font-semibold whitespace-nowrap;
}

label {
  @apply font-light;
}
</style>
