<template>
  <div class="flex flex-col flex-wrap">
    <table>
      <tr>
        <td colspan="3">
          <div class="yb-text-label">
            <yb-icon class="yb-symbol-icon-md mr-1" icon="sql" />Results
          </div>
        </td>
      </tr>
      <tr v-if="selectedQuery.num_error">
        <td><strong v-translate>Error Count</strong></td>
        <td>{{ number(selectedQuery.num_error) }}</td>
      </tr>
      <tr v-if="selectedQuery.num_restart">
        <td><strong v-translate>Restart Count</strong></td>
        <td>{{ number(selectedQuery.num_restart) }}</td>
      </tr>
      <tr v-if="selectedQuery.rows_returned">
        <td><strong v-translate>Rows Returned</strong></td>
        <td>{{ number(selectedQuery.rows_returned) }}</td>
      </tr>
      <tr v-if="selectedQuery.rows_inserted">
        <td><strong v-translate>Rows Inserted</strong></td>
        <td>{{ number(selectedQuery.rows_inserted) }}</td>
      </tr>
      <tr v-if="selectedQuery.rows_deleted">
        <td><strong v-translate>Rows Deleted</strong></td>
        <td>{{ number(selectedQuery.rows_deleted) }}</td>
      </tr>
    </table>

    <table>
      <tr>
        <td colspan="2">
          <div v-translate class="yb-text-label pt-4">
            <yb-icon class="yb-symbol-icon-md mr-1" icon="io" />I/O Utilization
          </div>
        </td>
      </tr>
      <tr v-if="selectedQuery.io_read_count">
        <td><strong v-translate>Read Count Total</strong></td>
        <td>{{ number(selectedQuery.io_read_count) }}</td>
      </tr>
      <tr v-if="selectedQuery.io_read_ms / 1 > 0">
        <td><strong v-translate>Read Time Total</strong></td>
        <td>{{ humanizeMs((selectedQuery.io_read_ms / 1), true, true) }}</td>
      </tr>
      <tr v-if="selectedQuery.io_read_bytes">
        <td><strong v-translate>Data Read Total</strong></td>
        <td>{{ bytes(selectedQuery.io_read_bytes) }}</td>
      </tr>
      <tr v-if="selectedQuery.io_write_count">
        <td><strong v-translate>Write Count Total</strong></td>
        <td>{{ number(selectedQuery.io_write_count) }}</td>
      </tr>
      <tr v-if="selectedQuery.io_write_ms / 1 > 0">
        <td><strong v-translate>Write Time Total</strong></td>
        <td>{{ humanizeMs((selectedQuery.io_write_ms / 1), true, true) }}</td>
      </tr>
      <tr v-if="selectedQuery.io_write_bytes">
        <td><strong v-translate>Data Written Total</strong></td>
        <td>{{ bytes(selectedQuery.io_write_bytes) }}</td>
      </tr>
      <tr v-if="selectedQuery.io_spill_read_bytes">
        <td><strong v-translate>Temporary Read I/O Total</strong></td>
        <td>{{ bytes(selectedQuery.io_spill_read_bytes) }}</td>
      </tr>
      <tr v-if="selectedQuery.io_spill_write_bytes">
        <td><strong v-translate>Temporary Write I/O Total</strong></td>
        <td>{{ bytes(selectedQuery.io_spill_write_bytes) }}</td>
      </tr>
      <tr v-if="selectedQuery.io_spill_space_bytes">
        <td><strong v-translate>Maximum Total Temporary Space per Worker</strong></td>
        <td>{{ bytes(selectedQuery.io_spill_space_bytes) }}</td>
      </tr>
      <tr v-if="selectedQuery.io_network_bytes">
        <td><strong v-translate>Network I/O Data Total</strong></td>
        <td>{{ bytes(selectedQuery.io_network_bytes) }}</td>
      </tr>
    </table>

    <table>
      <tr>
        <td colspan="3">
          <div v-translate class="yb-text-label pt-4">
            <yb-icon class="yb-symbol-icon-md mr-1 stroke-0" icon="file-graph" />Resource Utilization
          </div>
        </td>
      </tr>
      <tr>
        <td><strong v-translate>Memory Used Average per Worker</strong></td>
        <td>{{ bytes(selectedQuery.memory_bytes) }}</td>
      </tr>
      <tr>
        <td><strong v-translate>Memory Used Maximum</strong></td>
        <td>{{ bytes(selectedQuery.memory_bytes_max) }}</td>
      </tr>
      <tr>
        <td><strong v-translate>Memory Minimum Required per Worker</strong></td>
        <td>{{ bytes(selectedQuery.memory_required_bytes) }}</td>
      </tr>
      <tr>
        <td><strong v-translate>Memory Estimated per Worker</strong></td>
        <td>{{ bytes(selectedQuery.memory_estimated_bytes) }}</td>
      </tr>
      <tr>
        <td><strong v-translate>Memory Estimate Confidence</strong></td>
        <td>{{ translate(selectedQuery.memory_estimate_confidence) }}</td>
      </tr>
      <tr>
        <td><strong v-translate>Memory Granted per Worker</strong></td>
        <td>{{ bytes(selectedQuery.memory_granted_bytes) }}</td>
      </tr>
      <tr v-if="selectedQuery.cache_efficiency">
        <td><strong v-translate>Cache Efficiency</strong></td>
        <td>{{ percentage(selectedQuery.cache_efficiency) }}</td>
      </tr>
      <tr>
        <td><strong v-translate>Spill Space Average per Worker</strong></td>
        <td>{{ bytes(selectedQuery.io_spill_space_bytes) }}</td>
      </tr>
      <tr>
        <td><strong v-translate>Spill Space Maximum</strong></td>
        <td>{{ bytes(selectedQuery.io_spill_space_bytes_max) }}</td>
      </tr>
      <tr>
        <td><strong v-translate>Spill Space Granted per Worker</strong></td>
        <td>{{ bytes(selectedQuery.io_spill_space_granted_bytes) }}</td>
      </tr>
      <tr>
        <td><strong v-translate>Average CPU Utilization per Worker</strong></td>
        <td><span>{{ number(selectedQuery.cpu_percent) }}%</span></td>
      </tr>
      <tr>
        <td><strong v-translate>Max CPU Utilization</strong></td>
        <td><span>{{ number(selectedQuery.cpu_percent_max) }}%</span></td>
      </tr>
      <tr v-if="$toggles.queryDevStats">
        <td><strong v-translate>Execution Ticks</strong></td>
        <td>{{ number(selectedQuery.execution_cycles) }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import YbResultsMixin from './ResultsMixin'
import { humanizeMs, translate, number, bytes, percentage } from '@/filters'

export default {
  mixins: [YbResultsMixin],
  methods: {
    humanizeMs,
    translate,
    number,
    bytes,
    percentage
  }
}
</script>

<style scoped lang="postcss">

table {
  @apply w-1/2;
}

tr td:first-child {
  width: 340px;
  min-width: 340px;
}

td {
  @apply whitespace-nowrap align-text-top py-1;
}

table td strong {
  @apply font-medium;
}
</style>
