import { $gettext, $toggles } from '@/services'
import { humanizeMs, humanizeNumber, number, bytes } from '@/filters'

let highlightTypesFiltered = [
  { type: 'NNNN', label: $gettext('None') },
  { type: 'io_(spill_)?(read|write|network)_bytes', label: $gettext('I/O Activity'), filter: bytes },
  { type: 'io_read.*bytes', label: $gettext('Read I/O Activity'), filter: bytes },
  { type: 'io_write.*bytes', label: $gettext('Write I/O Activity'), filter: bytes },
  { type: 'io_spill_(read|write)_bytes', label: $gettext('Temporary I/O Activity'), filter: bytes },
  { type: 'io_network_bytes', label: $gettext('Network I/O Activity'), filter: bytes },
  { type: 'rows_actual', label: $gettext('Row Count'), filter: humanizeNumber },
  { type: 'memory_actual_bytes', label: $gettext('Memory Used'), filter: bytes },
  { type: 'skew', label: $gettext('Skew'), filter: number },
  {
    type: 'runtime_ms',
    label: $gettext('Time Active'),
    filter: humanizeMs,
    accessor: (node, query) => {
      return node.stats.runtime_ms
    }
  }
]

if (!$toggles.queryDevStats) {
  highlightTypesFiltered = highlightTypesFiltered.filter(type => !type.debug)
}

export const highlightTypes = highlightTypesFiltered

export function findHighlight(id) {
  return highlightTypes.find((highlightType) => {
    return highlightType.type === id
  })
}

export function findHighlightLabel(id) {
  const highlight = findHighlight(id)
  return highlight && highlight.label
}
