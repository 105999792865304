import YbNodeTooltip from './YbNodeTooltip.vue'
import YbTooltip from '@/components/YbTooltip.vue'

export default {
  components: {
    YbTooltip
  },
  data() {
    return {
      tooltip: null
    }
  },
  async beforeMount() {
    this.tooltip = YbNodeTooltip
  }
}
