export default {
  props: {
    o: Object,
    nodes: Object
  },
  computed: {
    classes() {
      const result = []
      const { o } = this
      const clazz = o.getClass()
      if (clazz) {
        result.push(clazz)
      }
      if (o.selected) {
        result.push('qp-node-selected')
      }
      return result
    }
  },
  methods: {
    open() {
      // no-op
    }
  }
}
