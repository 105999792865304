// NB: this module exists to fix the CommonJS module mania wihin the dagre/graphlib libs
//     It just so happens that the author left behind a way to have its external dependencies
//     locatable on the "window" object.

import clone from 'lodash.clone'
import cloneDeep from 'lodash.clonedeep'
import constant from 'lodash.constant'
import defaults from 'lodash.defaults'
import forEach from 'lodash.foreach'
import filter from 'lodash.filter'
import find from 'lodash.find'
import flatten from 'lodash.flatten'
import forIn from 'lodash.forin'
import has from 'lodash.has'
import isArray from 'lodash.isarray'
import isEmpty from 'lodash.isempty'
import isFunction from 'lodash.isfunction'
import isUndefined from 'lodash.isundefined'
import keys from 'lodash.keys'
import last from 'lodash.last'
import map from 'lodash.map'
import mapValues from 'lodash.mapvalues'
import max from 'lodash.max'
import merge from 'lodash.merge'
import min from 'lodash.min'
import minBy from 'lodash.minby'
import now from 'lodash.now'
import pick from 'lodash.pick'
import range from 'lodash.range'
import reduce from 'lodash.reduce'
import size from 'lodash.size'
import sortBy from 'lodash.sortby'
import transform from 'lodash.transform'
import union from 'lodash.union'
import uniqueId from 'lodash.uniqueid'
import values from 'lodash.values'
import zipObject from 'lodash.zipobject'

// Make faux lodash attached to "window"
window._ = {
  clone,
  cloneDeep,
  constant,
  defaults,
  each: forEach,
  filter,
  find,
  flatten,
  forEach,
  forIn,
  has,
  isArray,
  isEmpty,
  isFunction,
  isUndefined,
  keys,
  last,
  map,
  mapValues,
  max,
  merge,
  min,
  minBy,
  now,
  pick,
  range,
  reduce,
  size,
  sortBy,
  transform,
  uniqueId,
  union,
  values,
  zipObject
}
false && console.log('lodash', window._)

// We are exporting dynamically loaded modules.
let exportedResolve
const exported = new Promise((resolve, reject) => {
  exportedResolve = resolve
})
export default exported

// Attach graphlib to "window"
import('graphlib')
  .then(async (graphlib) => {
    window.graphlib = graphlib
    false && console.log('graphlib', window.graphlib)

    // Now load dagre and dagre util.
    const modules = await Promise.all([import('dagre'), import('dagre/lib/util')])
    exportedResolve({ dagre: modules[0].default, util: modules[1].default })
  })
