<template>
  <div @mouseover="mouseover" @mousemove="mousemove" @mouseout="mouseout">
    <slot />
    <div ref="content">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
import tooltipService from '@/services/tooltipService'

export default {
  props: {
    template: Object,
    meta: String,
    props: {
      type: Object,
      default: () => {}
    },
    delay: {
      type: Number,
      default: 500
    },
    disabled: {
      type: Boolean,
      default: true
    },
    show: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      hovered: false
    }
  },
  watch: {
    show(_) {
      this.hovered && tooltipService.style('display', 'block')
      this.hovered && tooltipService
        .transition()
        .delay(this.show ? this.delay : 0)
        .duration(this.show ? 500 : 0)
        .style('opacity', this.show ? 1 : 1e-6)
    }
  },
  mounted() {
    tooltipService.cleanup()
  },
  unmounted() {
    tooltipService.cleanup()
  },
  methods: {
    mouseover(event) {
      this.hovered = true
      if (this.disabled) {
        return
      }
      if (this.meta && !event[this.meta]) {
        return
      }
      if (event.ctrlKey) {
        return
      }
      tooltipService.reparent(event)
      tooltipService.style('display', 'block')
      tooltipService
        .transition()
        .delay(this.delay)
        .duration(500)
        .style('opacity', this.show ? 1 : 1e-6)
    },

    mousemove(event) {
      this.hovered = true
      if (this.disabled) {
        return
      }
      if (event.ctrlKey) {
        return
      }
      tooltipService.reparent(event)

      if (this.meta && !event[this.meta]) {
        tooltipService.style('display', 'block')
        tooltipService
          .transition()
          .duration(500)
          .style('opacity', this.show ? 1 : 1e-6)
      }

      // Resolve the props data as a possible promise, then setup the tooltip template
      if (this.show) {
        if (this.template) {
          Promise.resolve(this.props).then((item) => {
            const props = Object.assign({ meta: event.metaKey || event.altKey }, item)
            tooltipService.setup(this.template, props)
            tooltipService.update(props)
            tooltipService.adjustTip(event)
          })
        } else if (this.$refs.content) {
          tooltipService.setElement(this.$refs.content)
          tooltipService.adjustTip(event)
        }
      }
    },

    mouseout(event) {
      this.hovered = false
      if (this.disabled) {
        return
      }
      tooltipService
        .transition()
        .duration(500)
        .style('opacity', 1e-6)
        .on('end', () => {
          tooltipService.style('display', 'none')
          tooltipService.cleanup()
        })
    }
  }
}
</script>
