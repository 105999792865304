import { get } from 'vuex-pathify'
import { app, $gettext } from '@/services'
import { NotFound } from '@/services/errors'
import Instance from '@/models/Instance'

const DEFAULT_RETRIES = 5

export default {
  props: {
    instanceId: {
      type: String,
      required: true
    },
    queryIdParam: {
      type: Number,
      required: true
    },
    historical: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      detailsLoading: false
    }
  },
  beforeMount() {
    this.load(DEFAULT_RETRIES)
  },
  computed: {
    instance () {
      return Instance.query().where('id', this.instanceId).first()
    },
    queryId() {
      const result = this.queryIdParam || this.$route.params.id || this.editor?.rowset?.execId || 0
      return typeof result === 'number' ? result : undefined
    },
    selectedQuery: get('query-details/selectedQuery', false)
  },
  watch: {
    queryId() {
      this.load(DEFAULT_RETRIES)
    }
  },
  methods: {
    async load(retries) {
      if (!this.queryId || !!this.detailsLoading || this.selectedQuery?.query_id === this.queryId) {
        return
      }
      try {
        this.detailsLoading = true
        await this.$store.dispatch('query-details/populate', {
          instance: this.instance,
          query_id: this.queryId,
          ybSystemDebug: this.$toggles.queryDevStats,
          historical: this.historical
        })
      } catch (e) {
        if (retries > 1) {
          await new Promise((resolve, reject) => {
            window.setTimeout(async () => {
              try {
                this.detailsLoading = false
                await this.load(retries - 1)
              } finally {
                resolve()
              }
            }, 2000) // Retry in case system is lagging in writing sys.log_*
          })
        } else if (e instanceof NotFound) {
          app.warning($gettext('Could not locate the information about this query: ') + this.queryId)
        } else {
          console.log('Error retrieving query details', this.queryId)
          console.error(e)
          app.errorResult(e)
        }
      } finally {
        this.detailsLoading = false
      }
    },
    openNode(node) {
      throw new Error('TODO: Open node is not implemented')
    },
    open(query) {
      this.$emit('query', query.query_id || query)
    },
    reformat() {
      throw new Error('TODO: Reformat not implemented')
    }
  }
}
