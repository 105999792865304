<template>
  <yb-timeline
    v-if="!loading"
    ref="timeline"
    class="h-full w-full"
    :pools="pools"
    :queries="queries"
    :queue-indicators="[]"
    :auto-update="false"
    :zoom-range="zoomRange"
    :highlight-query="queryId"
  />
  <yb-loading v-else class="self-center text-center" />
</template>

<script>
import { databaseObjectService, errorHandler } from '@/services'

import YbTimeline from '@/app/timeline/Timeline.vue'
import YbQueryDetailsMixin from '@/app/query-details/queryDetailsMixin'

export default {
  components: {
    YbTimeline
  },
  mixins: [YbQueryDetailsMixin],
  data() {
    return {
      pools: [],
      queries: [],
      loading: false,
      zoomRange: 0
    }
  },
  beforeMount() {
    this.loadTimeline()
    this.interval = window.setInterval(this.redraw.bind(this), 100)
  },
  beforeUnmount() {
    this.interval && window.clearInterval(this.interval)
    delete this.interval
  },
  methods: {
    async loadTimeline() {
      this.loading = true
      try {
        const { selectedQuery } = this
        const startTime = +new Date(selectedQuery.submit_time) - (1 * 60 * 1000)
        const endTime = this.historical ? +new Date(selectedQuery.done_time || selectedQuery.state_time || selectedQuery.submit_time) + (1 * 60 * 1000) : +new Date();
        [this.queries, this.pools] = await Promise.all([
          databaseObjectService.concurrentQueries(this.instanceId, new Date(startTime), new Date(endTime), this.selectedQuery.cluster_name),
          databaseObjectService.poolsAtTime(this.instanceId, this.selectedQuery.query_id)
        ])
      } catch (e) {
        errorHandler.thrown(e, 'Could not load execution timeline information.')
      } finally {
        this.loading = false
      }
    },
    redraw() {
      this.$refs.timeline && this.$refs.timeline.highlight()
    }
  }
}

</script>
