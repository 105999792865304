<template>
  <div class="w-full h-full flex flex-row flex-nowrap items-center space-x-1 relative" :style="{ paddingLeft: (row.level * 12) + 'px'}">
    <div class="flex-shrink-0 h-5 w-5">
      <component :is="icon(row.icon)" />
    </div>
    <div class="font-medium">
      {{ row.label }}
    </div>
    <div class="truncate" style="max-width: 40vw">
      [{{ row.id }}] {{ row.explain }}
    </div>
    <div v-show="row.stats.io_spill_read_bytes || row.stats.io_spill_write_bytes" class="absolute -right-4 text-yb-orange">
      <yb-icon v-tooltip="'Query Node Used Temporary Space (Spill)'" class="yb-button-icon-lg" icon="warning" />
    </div>
  </div>
</template>

<script>
import iconsMixin from '@/app/query-details/html-query-plan/icons/iconsMixin'

export default {
  mixins: [iconsMixin],
  computed: {
    row() {
      return this.params.data
    }
  }
}
</script>
