<template>
  <div class="statistics-timing">
    <table class="table">
      <colgroup>
        <col class="w-6">
        <col width="350px">
      </colgroup>
      <tbody>
        <tr>
          <td />
          <td colspan="2">
            <div class="yb-text-label">
              <yb-icon class="yb-symbol-icon-md mr-1" icon="clock9" />Timeline
            </div>
          </td>
        </tr>
        <tr>
          <td />
          <td><strong v-translate>Submitted At</strong></td>
          <td>{{ formatDateTimeMs(selectedQuery.submit_time) }}</td>
        </tr>
        <tr>
          <td />
          <td><strong v-translate>Completed At</strong></td>
          <td>{{ formatDateTimeMs(selectedQuery.done_time) }}</td>
        </tr>
      </tbody>
    </table>

    <div class="flex flex-row flex-wrap">
      <table class="min-w-400 max-w-700 flex-grow table w-1/3 mr-20">
        <colgroup>
          <col class="w-6">
          <col width="350px">
        </colgroup>
        <tbody>
          <tr>
            <td />
            <td colspan="2">
              <div v-translate class="yb-text-label pt-4">
                <yb-icon class="yb-symbol-icon-md mr-1" icon="process" />Processing Times
              </div>
            </td>
          </tr>
          <tr>
            <td><div class="yb-time-bug" :style="queryTimeBugColor(0)" /></td>
            <td><strong v-translate>Parse Time</strong></td>
            <td><span v-tooltip="tooltip(selectedQuery.parse_ms)">{{ humanizeMs((selectedQuery.parse_ms / 1), true, true) }}</span></td>
          </tr>
          <tr>
            <td><div class="yb-time-bug" :style="queryTimeBugColor(1)" /></td>
            <td><strong v-translate>Plan Time</strong></td>
            <td><span v-tooltip="tooltip(selectedQuery.plan_ms)">{{ humanizeMs((selectedQuery.plan_ms / 1), true, true) }}</span></td>
          </tr>
          <tr>
            <td><div class="yb-time-bug" :style="queryTimeBugColor(2)" /></td>
            <td><strong v-translate>Assemble Time</strong></td>
            <td><span v-tooltip="tooltip(selectedQuery.assemble_ms)">{{ humanizeMs((selectedQuery.assemble_ms / 1), true, true) }}</span></td>
          </tr>
          <tr>
            <td><div class="yb-time-bug" :style="queryTimeBugColor(3)" /></td>
            <td><strong v-translate>Compile Time</strong></td>
            <td><span v-tooltip="tooltip(selectedQuery.compile_ms)">{{ humanizeMs((selectedQuery.compile_ms / 1), true, true) }}</span></td>
          </tr>
          <tr>
            <td><div class="yb-time-bug" :style="queryTimeBugColor(4)" /></td>
            <td><strong v-translate>Acquire Resources Time</strong></td>
            <td><span v-tooltip="tooltip(selectedQuery.acquire_resources_ms)">{{ humanizeMs((selectedQuery.acquire_resources_ms / 1), true, true) }}</span></td>
          </tr>
          <tr>
            <td><div class="yb-time-bug" :style="queryTimeBugColor(5)" /></td>
            <td><strong v-translate>Run Time</strong></td>
            <td><span v-tooltip="tooltip(selectedQuery.run_ms)">{{ humanizeMs((selectedQuery.run_ms / 1), true, true) }}</span></td>
          </tr>
          <tr>
            <td><div class="yb-time-bug" :style="queryTimeBugColor(6)" /></td>
            <td><strong v-translate>Client Wait Time</strong></td>
            <td><span v-tooltip="tooltip(selectedQuery.client_ms)">{{ humanizeMs((selectedQuery.client_ms / 1), true, true) }}</span></td>
          </tr>
          <tr>
            <td><div class="yb-time-bug" :style="queryTimeBugColor(7)" /></td>
            <td><strong v-translate>Cancel Time</strong></td>
            <td><span v-tooltip="tooltip(selectedQuery.cancel_ms)">{{ humanizeMs((selectedQuery.cancel_ms / 1), true, true) }}</span></td>
          </tr>
          <tr>
            <td><div class="yb-time-bug" :style="queryTimeBugColor(8)" /></td>
            <td><strong v-translate>Restart Time</strong></td>
            <td><span v-tooltip="tooltip(selectedQuery.restart_ms)">{{ humanizeMs((selectedQuery.restart_ms / 1), true, true) }}</span></td>
          </tr>
          <tr>
            <td colspan="3">
              <hr>
            </td>
          </tr>
          <tr>
            <td><div class="yb-time-bug" :style="queryTimeBugColor(-1)" /></td>
            <td>
              <strong v-translate>Total Time</strong>
            </td>
            <td><span v-tooltip="tooltip(selectedQuery.total_ms)">{{ humanizeMs((selectedQuery.total_ms / 1), true, true) }}</span></td>
          </tr>
          <tr>
            <td />
            <td colspan="2">
              <div class="full-width" style="height: 12px; display: flex; flex-direction: row; margin-top: 4px;">
                <div v-tooltip="'Parse Time'" class="yb-time-bug-display" :style="queryTimeBug(0, selectedQuery.parse_ms, selectedQuery.total_known_ms)" />
                <div v-tooltip="'Plan Time'" class="yb-time-bug-display" :style="queryTimeBug(1, selectedQuery.plan_ms, selectedQuery.total_known_ms)" />
                <div v-tooltip="'Assemble Time'" class="yb-time-bug-display" :style="queryTimeBug(2, selectedQuery.assemble_ms, selectedQuery.total_known_ms)" />
                <div v-tooltip="'Compile Time'" class="yb-time-bug-display" :style="queryTimeBug(3, selectedQuery.compile_ms, selectedQuery.total_known_ms)" />
                <div v-tooltip="'Acquire Resources Time'" class="yb-time-bug-display" :style="queryTimeBug(4, selectedQuery.acquire_resources_ms, selectedQuery.total_known_ms)" />
                <div v-tooltip="'Run Time'" class="yb-time-bug-display" :style="queryTimeBug(5, selectedQuery.run_ms, selectedQuery.total_known_ms)" />
                <div v-tooltip="'Client Wait Time'" class="yb-time-bug-display" :style="queryTimeBug(6, selectedQuery.client_ms, selectedQuery.total_known_ms)" />
                <div v-tooltip="'Cancel Time'" class="yb-time-bug-display" :style="queryTimeBug(7, selectedQuery.cancel_ms, selectedQuery.total_known_ms)" />
                <div v-tooltip="'Restart Time'" class="yb-time-bug-display" :style="queryTimeBug(8, selectedQuery.restart_ms, selectedQuery.total_known_ms)" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="min-w-400 max-w-700 flex-grow table w-1/3 mr-2">
        <colgroup>
          <col class="w-6">
          <col width="350px">
        </colgroup>
        <tbody>
          <tr>
            <td />
            <td colspan="2">
              <div v-translate class="yb-text-label pt-4">
                <yb-icon class="yb-symbol-icon-md mr-1" icon="loading" />Wait/Other Times
              </div>
            </td>
          </tr>
          <tr>
            <td><div class="yb-time-bug" :style="queryTimeBugColor(0)" /></td>
            <td><strong v-translate>Wait Parse Time</strong></td>
            <td><span v-tooltip="tooltip(selectedQuery.wait_parse_ms)">{{ humanizeMs((selectedQuery.wait_parse_ms / 1), true, true) }}</span></td>
          </tr>
          <tr>
            <td><div class="yb-time-bug" :style="queryTimeBugColor(1)" /></td>
            <td><strong v-translate>Wait Lock Time</strong></td>
            <td><span v-tooltip="tooltip(selectedQuery.wait_lock_ms)">{{ humanizeMs((selectedQuery.wait_lock_ms / 1), true, true) }}</span></td>
          </tr>
          <tr>
            <td><div class="yb-time-bug" :style="queryTimeBugColor(2)" /></td>
            <td><strong v-translate>Wait Plan Time</strong></td>
            <td><span v-tooltip="tooltip(selectedQuery.wait_plan_ms)">{{ humanizeMs((selectedQuery.wait_plan_ms / 1), true, true) }}</span></td>
          </tr>
          <tr>
            <td><div class="yb-time-bug" :style="queryTimeBugColor(3)" /></td>
            <td><strong v-translate>Wait Assemble Time</strong></td>
            <td><span v-tooltip="tooltip(selectedQuery.wait_assemble_ms)">{{ humanizeMs((selectedQuery.wait_assemble_ms / 1), true, true) }}</span></td>
          </tr>
          <tr>
            <td><div class="yb-time-bug" :style="queryTimeBugColor(4)" /></td>
            <td><strong v-translate>Wait Compile Time</strong></td>
            <td><span v-tooltip="tooltip(selectedQuery.wait_compile_ms)">{{ humanizeMs((selectedQuery.wait_compile_ms / 1), true, true) }}</span></td>
          </tr>
          <tr>
            <td><div class="yb-time-bug" :style="queryTimeBugColor(5)" /></td>
            <td><strong v-translate>Wait Run CPU Time</strong></td>
            <td><span v-tooltip="tooltip(selectedQuery.wait_run_cpu_ms)">{{ humanizeMs((selectedQuery.wait_run_cpu_ms / 1), true, true) }}</span></td>
          </tr>
          <tr>
            <td><div class="yb-time-bug" :style="queryTimeBugColor(6)" /></td>
            <td><strong v-translate>Wait Run I/O Time</strong></td>
            <td><span v-tooltip="tooltip(selectedQuery.wait_run_io_ms)">{{ humanizeMs((selectedQuery.wait_run_io_ms / 1), true, true) }}</span></td>
          </tr>
          <tr>
            <td><div class="yb-time-bug" :style="queryTimeBugColor(7)" /></td>
            <td><strong v-translate>Wait Run Spool I/O Time</strong></td>
            <td><span v-tooltip="tooltip(selectedQuery.wait_run_spool_ms)">{{ humanizeMs((selectedQuery.wait_run_spool_ms / 1), true, true) }}</span></td>
          </tr>
          <tr>
            <td><div class="yb-time-bug" :style="queryTimeBugColor(8)" /></td>
            <td><strong v-translate>Wait Spool Time</strong></td>
            <td><span v-tooltip="tooltip(selectedQuery.spool_ms)">{{ humanizeMs((selectedQuery.spool_ms / 1), true, true) }}</span></td>
          </tr>
          <tr>
            <td><div class="yb-time-bug" :style="queryTimeBugColor(9)" /></td>
            <td><strong v-translate>Wait Client Time</strong></td>
            <td><span v-tooltip="tooltip(selectedQuery.wait_client_ms)">{{ humanizeMs((selectedQuery.wait_client_ms / 1), true, true) }}</span></td>
          </tr>
          <tr>
            <td><div class="yb-time-bug" :style="queryTimeBugColor(10)" /></td>
            <td><strong v-translate>WLM Script Time</strong></td>
            <td><span v-tooltip="tooltip(selectedQuery.wlm_runtime_ms)">{{ humanizeMs((selectedQuery.wlm_runtime_ms / 1), true, true) }}</span></td>
          </tr>
          <tr>
            <td colspan="3">
              <hr>
            </td>
          </tr>
          <tr>
            <td><div class="yb-time-bug" :style="queryTimeBugColor(-1)" /></td>
            <td>
              <strong v-translate>
                Total Wait/Other Time
              </strong>
            </td>
            <td><span v-tooltip="tooltip(selectedQuery.total_wait_ms)">{{ humanizeMs((selectedQuery.total_wait_ms / 1), true, true) }}</span></td>
          </tr>
          <tr>
            <td />
            <td colspan="2">
              <div class="full-width" style="height: 12px; display: flex; flex-direction: row; margin-top: 4px;">
                <div v-tooltip="'Wait Parse Time'" class="yb-time-bug-display" :style="queryTimeBug(0, selectedQuery.wait_parse_ms, selectedQuery.total_wait_ms)" />
                <div v-tooltip="'Wait Lock Time'" class="yb-time-bug-display" :style="queryTimeBug(1, selectedQuery.wait_lock_ms, selectedQuery.total_wait_ms)" />
                <div v-tooltip="'Wait Plan Time'" class="yb-time-bug-display" :style="queryTimeBug(2, selectedQuery.wait_plan_ms, selectedQuery.total_wait_ms)" />
                <div v-tooltip="'Wait Assemble Time'" class="yb-time-bug-display" :style="queryTimeBug(3, selectedQuery.wait_assemble_ms, selectedQuery.total_wait_ms)" />
                <div v-tooltip="'Wait Compile Time'" class="yb-time-bug-display" :style="queryTimeBug(4, selectedQuery.wait_compile_ms, selectedQuery.total_wait_ms)" />
                <div v-tooltip="'Wait Run CPU Time'" class="yb-time-bug-display" :style="queryTimeBug(5, selectedQuery.wait_run_cpu_ms, selectedQuery.total_wait_ms)" />
                <div v-tooltip="'Wait Run I/O Time'" class="yb-time-bug-display" :style="queryTimeBug(6, selectedQuery.wait_run_io_ms, selectedQuery.total_wait_ms)" />
                <div v-tooltip="'Wait Run Spool Time'" class="yb-time-bug-display" :style="queryTimeBug(7, selectedQuery.wait_run_spool_ms, selectedQuery.total_wait_ms)" />
                <div v-tooltip="'Wait Spool Time'" class="yb-time-bug-display" :style="queryTimeBug(8, selectedQuery.wait_spool_ms, selectedQuery.total_wait_ms)" />
                <div v-tooltip="'Wait Client Time'" class="yb-time-bug-display" :style="queryTimeBug(9, selectedQuery.wait_client_ms, selectedQuery.total_wait_ms)" />
                <div v-tooltip="'Wait WLM Script Time'" class="yb-time-bug-display" :style="queryTimeBug(10, selectedQuery.wlm_runtime_ms, selectedQuery.total_wait_ms)" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import YbResultsMixin from './ResultsMixin'
import { number, humanizeMs, formatDateTimeMs } from '@/filters'
import { seriesColors } from '@/services/constants'

const colors = index => seriesColors[index % seriesColors.length].bg

export default {
  mixins: [YbResultsMixin],
  methods: {
    humanizeMs,
    formatDateTimeMs,
    queryTimeBugColor(index) {
      return {
        background: index < 0 ? 'transparent' : colors(index)
      }
    },
    queryTimeBug(index, frac, total) {
      return Object.assign({
        width: ((frac / total) * 100) + '%'
      }, this.queryTimeBugColor(index))
    },
    tooltip(value) {
      return number(value, 0) + 'ms'
    }
  }
}
</script>

<style scoped>
.yb-time-bug {
  width: 12px;
  height: 12px;
}

.yb-time-bug-display {
  @apply inline-block;
}

.statistics-timing * {
  @apply whitespace-nowrap;
}

table td {
  @apply py-1;
}

table td strong {
  @apply font-medium;
}
</style>
