<template>
  <div v-if="selectedQuery" class="w-full h-full">
    <template v-if="detailsLoading">
      <yb-loading v-show="detailsLoading" class="self-center text-center" />
    </template>
    <tabs v-else class="w-full h-full vertical">
      <tab title="Timing" data-test-id="timing" class="overflow-auto">
        <yb-statistics-timing />
      </tab>
      <tab title="Resources" data-test-id="resources" class="overflow-auto">
        <yb-statistics-resources />
      </tab>
      <tab v-if="$toggles.queryDevStats" title="Kernel (Developer)" class="overflow-auto">
        <yb-statistics-detail />
      </tab>
      <tab title="Utilization" data-test-id="utilization" class="overflow-auto">
        <yb-statistics-utilization />
      </tab>
    </tabs>
  </div>
</template>

<script>
import YbStatisticsTiming from '../query/StatisticsTiming.vue'
import YbStatisticsResources from '../query/StatisticsResources.vue'
import YbStatisticsDetail from '../query/StatisticsDetail.vue'
import YbStatisticsUtilization from '../query/StatisticsUtilization.vue'
import YbQueryDetailsMixin from '@/app/query-details/queryDetailsMixin'

export default {
  components: {
    YbStatisticsTiming,
    YbStatisticsResources,
    YbStatisticsDetail,
    YbStatisticsUtilization
  },
  mixins: [YbQueryDetailsMixin]
}
</script>
