<template>
  <div class="yb-view">
    <div class="yb-view-content-auto flex flex-col flex-nowrap space-y-8 w-full mr-8">
      <div v-if="selectedQuery.detail.queryAlerts.length">
        <div class="yb-text-label">
          <yb-icon class="yb-symbol-icon mr-1" icon="alert" />
          <translate>Alerts</translate>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th translate>
                Timestamp
              </th>
              <th translate>
                Severity
              </th>
              <th translate>
                Source
              </th>
              <th translate>
                Message
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in selectedQuery.detail.queryAlerts" :key="index">
              <td>{{ formatDateTimeMs(row.alert_time) }}</td>
              <td>
                <div :class="getSeverityClass(row)">
                  {{ getSeverityLabel(row) }}
                </div>
              </td>
              <td>{{ row.source }}</td>
              <td>{{ row.message }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="selectedQuery.detail.nodesByType.tableScan">
        <div class="yb-text-label">
          <div class="yb-summary-icon">
            <yb-table-scan-icon />
          </div>
          <translate>Table Scan</translate>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th translate>
                ID
              </th>
              <th translate>
                Rows
              </th>
              <th translate>
                Table
              </th>
              <th translate>
                Constraints
              </th>
              <th translate>
                Data Read
              </th>
              <th translate>
                Read Efficiency
              </th>
              <th translate>
                Columns
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(node, index) in selectedQuery.detail.nodesByType.tableScan" :key="index">
              <td>
                <a class="yb-link" @click="openNode(node)">{{ node.id }}</a>
              </td>
              <td nowrap>
                {{ number(node.stats.rows_actual) }}
              </td>
              <td>{{ node.explain }}</td>
              <td>{{ join(node.expressions) }}</td>
              <td nowrap>
                {{ bytes(node.stats.io_read_bytes) }}
              </td>
              <td nowrap>
                {{ node.stats.read_efficiency }}
              </td>
              <td class="whitespace-nowrap">
                <div v-for="(col, index) in node.columnNames" :key="index" class="yb-text-expr">
                  {{ col }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="hasJoin">
        <div class="yb-text-label">
          <div class="yb-summary-icon">
            <yb-hash-join-icon />
          </div>
          <translate>Join</translate>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th translate>
                ID
              </th>
              <th translate>
                Type
              </th>
              <th translate>
                Rows
              </th>
              <th translate>
                Memory
              </th>
              <th translate>
                Input Rows
              </th>
              <th translate>
                Build Rows
              </th>
              <th translate>
                Build Memory
              </th>
              <th translate>
                Build Type
              </th>
              <th translate>
                Temp Data Read
              </th>
              <th translate>
                Temp Data Written
              </th>
              <th translate>
                Columns/Expressions
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="joinType in joinTypes">
              <tr v-for="(node, index) in selectedQuery.detail.nodesByType[joinType]" :key="index">
                <td>
                  <a class="yb-link" @click="openNode(node)">{{ node.id }}</a>
                </td>
                <td nowrap>
                  {{ node._nodeName }}
                </td>
                <td nowrap>
                  {{ number(node.stats.rows_actual) }}
                </td>
                <td nowrap>
                  {{ bytes(node.stats.memory_actual_bytes) }}
                </td>
                <td nowrap>
                  {{ number(node.children[1].stats.rows_actual) }}
                </td>
                <td nowrap>
                  {{ number(node.children[0].stats.rows_actual) }}
                </td>
                <td nowrap>
                  {{ bytes(node.children[0].stats.memory_actual_bytes) }}
                </td>
                <td nowrap>
                  {{ node.children[0]._nodeName }}
                </td>
                <td nowrap>
                  {{ bytes(node.children[0].stats.io_spill_read_bytes) }}
                </td>
                <td nowrap>
                  {{ bytes(node.children[0].stats.io_spill_write_bytes) }}
                </td>
                <td class="whitespace-nowrap">
                  <div v-for="(col, index) in node.children[0].columnNames" :key="index" class="yb-text-expr">
                    {{ col }}
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <div v-if="selectedQuery.detail.nodesByType.distribute">
        <div class="yb-text-label">
          <div class="yb-summary-icon">
            <yb-distribute-map-icon />
          </div>
          <translate>Distribution</translate>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th translate>
                ID
              </th>
              <th translate>
                Rows
              </th>
              <th translate>
                Data Sent
              </th>
              <th translate>
                Memory
              </th>
              <th translate>
                Type
              </th>
              <th translate>
                Columns/Expressions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(node, index) in selectedQuery.detail.nodesByType.distribute" :key="index">
              <td>
                <a class="yb-link" @click="openNode(node)">{{ node.id }}</a>
              </td>
              <td nowrap>
                {{ number(node.stats.rows_actual) }}
              </td>
              <td nowrap>
                {{ bytes(node.stats.io_network_bytes) }}
              </td>
              <td nowrap>
                {{ bytes(node.stats.memory_actual_bytes) }}
              </td>
              <td nowrap>
                {{ node.nodeType }}
              </td>
              <td class="whitespace-nowrap">
                <div v-for="(col, index) in split(node.distType)" :key="index" class="yb-text-expr">
                  {{ col }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="hasSort">
        <div class="yb-text-label">
          <div class="yb-summary-icon">
            <yb-sort-icon />
          </div>
          <translate>Sort</translate>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th translate>
                ID
              </th>
              <th translate>
                Rows
              </th>
              <th translate>
                Memory
              </th>
              <th translate>
                Temp Data Read
              </th>
              <th translate>
                Temp Data Written
              </th>
              <th translate>
                Columns/Expressions
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="sortType in sortTypes">
              <tr v-for="(node, index) in selectedQuery.detail.nodesByType[sortType]" :key="index">
                <td>
                  <a class="yb-link" @click="openNode(node)">{{ node.id }}</a>
                </td>
                <td nowrap>
                  {{ number(node.stats.rows_actual) }}
                </td>
                <td nowrap>
                  {{ bytes(node.stats.memory_actual_bytes) }}
                </td>
                <td nowrap>
                  {{ bytes(node.stats.io_spill_read_bytes) }}
                </td>
                <td nowrap>
                  {{ bytes(node.stats.io_spill_write_bytes) }}
                </td>
                <td class="whitespace-nowrap">
                  <div v-for="(col, index) in node.columnNames" :key="index" class="yb-text-expr">
                    {{ col }}
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <div v-if="hasAgg">
        <div class="yb-text-label">
          <div class="yb-summary-icon">
            <yb-agg-hash-icon />
          </div>
          <translate>Aggregate</translate>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th translate>
                ID
              </th>
              <th translate>
                Type
              </th>
              <th translate>
                Rows
              </th>
              <th translate>
                Memory
              </th>
              <th translate>
                Temp Data Read
              </th>
              <th translate>
                Temp Data Written
              </th>
              <th translate>
                Columns/Expressions
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="aggType in aggTypes">
              <tr v-for="(node, index) in selectedQuery.detail.nodesByType[aggType]" :key="index">
                <td>
                  <a class="yb-link" @click="openNode(node)">{{ node.id }}</a>
                </td>
                <td nowrap>
                  {{ node._nodeName }}
                </td>
                <td nowrap>
                  {{ number(node.stats.rows_actual) }}
                </td>
                <td nowrap>
                  {{ bytes(node.stats.memory_actual_bytes) }}
                </td>
                <td nowrap>
                  {{ bytes(node.stats.io_spill_read_bytes) }}
                </td>
                <td nowrap>
                  {{ bytes(node.stats.io_spill_write_bytes) }}
                </td>
                <td class="whitespace-nowrap">
                  <div v-for="(col, index) in node.columnNames" :key="index" class="yb-text-expr">
                    {{ col }}
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <div v-if="selectedQuery.detail.nodesByType.dataInput">
        <div class="yb-text-label">
          <div class="yb-summary-icon">
            <yb-data-input-icon />
          </div>
          <translate>Data Input</translate>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th translate>
                ID
              </th>
              <th translate>
                Rows
              </th>
              <th translate>
                Memory
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(node, index) in selectedQuery.detail.nodesByType.dataInput" :key="index">
              <td>
                <a class="yb-link" @click="openNode(node)">{{ node.id }}</a>
              </td>
              <td nowrap>
                {{ number(node.stats.rows_actual) }}
              </td>
              <td nowrap>
                {{ bytes(node.stats.memory_actual_bytes) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="selectedQuery.detail.nodesByType.modifyTable">
        <div class="yb-text-label">
          <div class="yb-summary-icon">
            <yb-modify-table-icon />
          </div>
          <translate>Modify Table</translate>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th translate>
                ID
              </th>
              <th translate>
                Rows
              </th>
              <th translate>
                Data Written
              </th>
              <th translate>
                Memory
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(node, index) in selectedQuery.detail.nodesByType.modifyTable" :key="index">
              <td>
                <a class="yb-link" @click="openNode(node)">{{ node.id }}</a>
              </td>
              <td>{{ number(node.stats.rows_actual) }}</td>
              <td>{{ bytes(node.stats.io_write_bytes) }}</td>
              <td>{{ bytes(node.stats.memory_actual_bytes) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="selectedNode" class="yb-view-right w-80 2xl:w-96 relative">
      <div class="h-full flex flex-col justify-center p-3 overflow-hidden rounded-sm border yb-border-content bg-yb-gray-faintest dark:bg-yb-gray-mud ml-2">
        <yb-node-tooltip :o="selectedNode" :nodes="nodes" />
      </div>

      <yb-close small @click="selectedNode = null" />
    </div>
  </div>
</template>

<script>
import YbResultsMixin from './ResultsMixin'
import { app } from '@/services'
import YbTableScanIcon from '@/app/query-details/html-query-plan/icons/tableScan.svg'
import YbSortIcon from '@/app/query-details/html-query-plan/icons/sort.svg'
import YbHashJoinIcon from '@/app/query-details/html-query-plan/icons/hashJoin.svg'
import YbAggHashIcon from '@/app/query-details/html-query-plan/icons/aggHash.svg'
import YbWindowAggIcon from '@/app/query-details/html-query-plan/icons/windowAgg.svg'
import YbDataInputIcon from '@/app/query-details/html-query-plan/icons/dataInput.svg'
import YbDistributeMapIcon from '@/app/query-details/html-query-plan/icons/distributeMap.svg'
import YbModifyTableIcon from '@/app/query-details/html-query-plan/icons/modifyTable.svg'
import YbNodeTooltip from '@/app/query-details/html-query-plan/YbNodeTooltip.vue'
import { join, formatDateTimeMs, number, bytes } from '@/filters'

export default {
  components: {
    YbTableScanIcon,
    YbSortIcon,
    YbHashJoinIcon,
    YbAggHashIcon,
    YbWindowAggIcon,
    YbDataInputIcon,
    YbDistributeMapIcon,
    YbModifyTableIcon,
    YbNodeTooltip
  },
  mixins: [YbResultsMixin],
  data: function() {
    return {
      joinTypes: [
        'join',
        'hashJoin',
        'hashEquiJoin',
        'equiJoin'
      ],
      aggTypes: [
        'agg',
        'aggHash',
        'windowAgg'
      ],
      sortTypes: [
        'sort',
        'sortMerge'
      ],
      selectedNode: null,
      nodes: {}
    }
  },
  computed: {
    hasJoin() {
      return this.hasType(this.joinTypes)
    },
    hasAgg() {
      return this.hasType(this.aggTypes)
    },
    hasSort() {
      return this.hasType(this.sortTypes)
    }
  },
  methods: {
    join,
    formatDateTimeMs,
    number,
    bytes,
    getSeverityClass(row) {
      return `smc-alert-severity-${app.getSeverityClass(row.severity)}`
    },
    getSeverityLabel(row) {
      return app.getSeverityLabel(row.severity)
    },
    split(s) {
      return s.split(/,/g).map(str => str.trim())
    },
    hasType(types) {
      const { selectedQuery } = this
      let result = false
      types.forEach((type) => {
        result |= type in selectedQuery.detail.nodesByType
      })
      return result
    },
    openNode(n) {
      if (n === this.selectedNode || n?.id === this.selectedNode?.id) {
        this.selectedNode = null
      } else {
        this.selectedNode = n
      }
    }
  }
}
</script>

<style scoped lang="postcss">

.table {
  @apply text-base;
}

.yb-summary-icon {
  @apply inline-block mr-0.5 h-10 w-10;
}

.yb-summary-icon .yb-svg-icon * {
  stroke-width: 1px;
  @apply opacity-70;
}

.table {
  @apply w-auto mt-1 min-w-full;
}

.table th {
  @apply whitespace-nowrap px-4 py-2 text-left font-semibold bg-yb-gray-alt-lightest dark:bg-yb-gray-alt-lightest-inverse;
}

.table td {
  @apply px-4 py-2 align-text-top;
}

.yb-text-label {
}

.yb-text-expr {
  @apply py-0.5;
}

.yb-link {
  @apply font-semibold;
}

</style>
