<template>
  <yb-tooltip v-if="o" :template="tooltip" :props="{ o, nodes }" class="is-inline">
    <a href="" @click="open(o)">
      <span>{{ translate(o.nodeType) }}</span>
      <span>&nbsp;&nbsp;[{{ o.cid }}]</span>
    </a>
  </yb-tooltip>
</template>

<script>
import nodeMixin from './nodeMixin'
import tooltipMixin from './tooltipMixin'
import { translate } from '@/filters'

export default {
  mixins: [nodeMixin, tooltipMixin],
  methods: {
    translate
  }
}
</script>
