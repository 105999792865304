<template>
  <div v-if="$toggles.queryDevStats && selectedQuery.detail.statistics.length" class="row">
    <table class="table">
      <thead>
        <tr>
          <th translate>
            Statistic
          </th>
          <th translate>
            Node
          </th>
          <th translate>
            Value
          </th>
          <th translate>
            Total
          </th>
          <th translate>
            Minimum
          </th>
          <th translate>
            Maximum
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(stat, index) in selectedQuery.detail.statistics" :key="index">
          <td><strong>{{ stat.stat_name }}</strong></td>
          <td>{{ stat.worker_id }}</td>
          <td v-tooltip="number(stat.stat_sum)">
            {{ niceStat(stat, 'stat_sum', selectedQuery) }}
          </td>
          <td v-tooltip="number(stat.stat_count)">
            {{ niceStat(stat, 'stat_count', selectedQuery) }}
          </td>
          <td v-tooltip="number(stat.stat_min)">
            {{ niceStat(stat, 'stat_min', selectedQuery) }}
          </td>
          <td v-tooltip="number(stat.stat_max)">
            {{ niceStat(stat, 'stat_max', selectedQuery) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { number, bytes, humanizeMs } from '@/filters'

export default {
  methods: {
    number,
    niceStat(stat, item, query) {
      const value = stat[item]
      if (stat && stat.stat_name) {
        if (stat.stat_name.match(/bytes$/i)) {
          return bytes(value)
        } else if (stat.stat_name.match(/time$/i)) {
          if (!item.match(/count$/)) {
            let result = number(value) + ' ticks'
            if (query) {
              const ms = (query.execution_time * (value / query.execution_cycles)) / 1000
              result = humanizeMs(ms) //  + '  (' + result + ')';
            }
            return result
          }
        }
      }
      return number(value)
    }
  }
}
</script>
