<template>
  <tabs v-if="selectedQuery" class="h-full vertical">
    <tab title="Details">
      <table class="table-auto details w-full">
        <tr>
          <td colspan="2">
            <div class="font-semibold">
              Query State
            </div>
            <hr>
          </td>
        </tr>
        <tr>
          <td class="pr-4 pb-2">
            <div class="flex flex-row flex-nowrap items-center justify-between w-40">
              <div>Query ID</div>
              <yb-clipboard-icon v-tooltip="'Copy query id to clipboard'" :value="selectedQuery.query_id" />
            </div>
          </td>
          <td>{{ selectedQuery.query_id }}</td>
        </tr>
        <tr>
          <td class="pr-4 pb-2">
            <div class="flex flex-row flex-nowrap items-center justify-between w-40">
              <div>Plan ID</div>
              <yb-clipboard-icon v-tooltip="'Copy plan id to clipboard'" :value="selectedQuery.plan_id" />
            </div>
          </td>
          <td>{{ selectedQuery.plan_id }}</td>
        </tr>
        <tr>
          <td class="pr-4 pb-2">
            State
          </td>
          <td>{{ selectedQuery.state }}</td>
        </tr>
        <tr>
          <td class="pr-4 pb-2">
            Error Code
          </td>
          <td>{{ selectedQuery.error_code }}</td>
        </tr>
        <tr>
          <td class="pr-4 pb-2">
            Error Message
          </td>
          <td>{{ selectedQuery.error_message }}</td>
        </tr>
        <tr>
          <td class="pr-4 pb-2">
            Resource Pool
          </td>
          <td>{{ selectedQuery.pool_id }}</td>
        </tr>
        <tr>
          <td class="pr-4 pb-2">
            Workers
          </td>
          <td>{{ selectedQuery.num_workers }}</td>
        </tr>
        <tr>
          <td class="pr-4 pb-2">
            Query Tags
          </td>
          <td>{{ selectedQuery.tags }}</td>
        </tr>
        <tr>
          <td class="pr-4 pb-2">
            Priority
          </td>
          <td>{{ selectedQuery.priority }}</td>
        </tr>

        <tr>
          <td colspan="2">
            <div class="font-semibold mt-8">
              Session State
            </div>
            <hr>
          </td>
        </tr>
        <tr>
          <td class="pr-4 pb-2">
            <div class="flex flex-row flex-nowrap items-center justify-between w-40">
              <div>Session ID</div>
              <yb-clipboard-icon v-tooltip="'Copy session id to clipboard'" :value="selectedQuery.session_id" />
            </div>
          </td>
          <td>{{ selectedQuery.session_id }}</td>
        </tr>
        <tr>
          <td class="pr-4 pb-2">
            Database Name
          </td>
          <td>{{ selectedQuery.database_name }}</td>
        </tr>
        <tr>
          <td class="pr-4 pb-2">
            User Name
          </td>
          <td>
            {{ selectedQuery.username }}
            <template v-if="selectedQuery.username && selectedQuery.authenticatedUserName && selectedQuery.username != selectedQuery.authenticatedUserName">
              &nbsp;&nbsp;({{ selectedQuery.authenticatedUserName }})
            </template>
          </td>
        </tr>
        <tr>
          <td class="pr-4 pb-2">
            Application Name
          </td>
          <td>{{ selectedQuery.application_name }}</td>
        </tr>
        <tr>
          <td class="pr-4 pb-2">
            Cluster Name
          </td>
          <td>{{ selectedQuery.cluster_name }}</td>
        </tr>
      </table>
    </tab>
    <tab title="SQL" class="overflow-auto" data-test-id="sql">
      <div class="flex flex-row flex-nowrap items-center justify-between">
        <div class="font-semibold">
          SQL Query Text
        </div>
        <yb-button
          v-yb-clipboard:text="selectedQuery.query_text || queryText"
          class="mb-2 border border-yb-gray-faint"
          clipboard-label="(SQL Query)"
        >
          <yb-icon class="yb-button-icon" icon="clipboard" />
          <translate>Copy to Clipboard</translate>
        </yb-button>
      </div>
      <hr class="mb-4">
      <yb-highlight style="white-space: pre-wrap" language="sql" :content="selectedQuery.query_text || queryText" class="yb-sql select-text" />
    </tab>
    <tab title="History" class="yb-view relative" data-test-id="history">
      <div class="yb-view-content-auto pb-24">
        <yb-query-details-history
          v-if="selectedQuery.database_name && selectedQuery.plan_id"
          :query-id="queryId"
          :instance-id="instanceId"
          :database="selectedQuery.database_name"
          :plan-id="selectedQuery.plan_id"
          @query="$emit('load-query', $event)"
        />
      </div>
    </tab>
  </tabs>
</template>

<script>
import YbQueryDetailsHistory from '@/app/instance/QueryDetailsHistory.vue'
import YbQueryDetailsMixin from '@/app/query-details/queryDetailsMixin'

export default {
  components: {
    YbQueryDetailsHistory
  },
  mixins: [YbQueryDetailsMixin],
  props: {
    queryText: {
      type: String
    }
  }
}
</script>

<style scoped lang="postcss">
table.details td:first-child {
  @apply w-32 font-medium;
}

table.details td {
  @apply p-1;
}

</style>
