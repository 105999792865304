<template>
  <div class="yb-view cursor-default select-none" style="max-width: 40vw">
    <div class="yb-view-header">
      <div v-if="o.stats && o.stats.iterations" class="qp-stat-iterations">
        <div class="qp-stat-iterations-label">
          {{ o.stats.iterations }}&nbsp;<i class="fa fa-refresh fa-spin" />
        </div>
      </div>
      <div v-show="!dialog" class="qp-header-icon">
        <div class="flex flex-row flex-nowrap items-center justify-center space-x-2">
          <div class="flex-grow-0 qp-icon-large">
            <component :is="icon(o.icon)" />
          </div>
          <div class="font-semibold text-lg">
            {{ translate(o.nodeType) }}
          </div>
          <div class="font-light text-base">
            &nbsp;&nbsp;[{{ o.cid }}]
          </div>
        </div>
      </div>
    </div>
    <div class="yb-view-content-auto">
      <yb-node-info :o="o" :nodes="nodes" />
    </div>
  </div>
</template>

<script>
import YbNodeInfo from './YbNodeInfo.vue'
import iconsMixin from './icons/iconsMixin'
import nodeMixin from './nodeMixin'
import { translate } from '@/filters'

export default {
  components: {
    YbNodeInfo
  },
  mixins: [nodeMixin, iconsMixin],
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    translate
  }
}
</script>
