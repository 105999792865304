<template>
  <div class="w-full h-full pt-2 pr-2">
    <yb-query-plan v-show="!detailsLoading" :selected-query="selectedQuery" :hide-fullscreen="true" />
    <yb-loading v-show="detailsLoading" class="self-center text-center" />
  </div>
</template>

<script>
import * as editorHelper from '../query/editorHelper'
import YbQueryPlan from '@/app/query-details/Plan.vue'
import YbQueryDetailsMixin from '@/app/query-details/queryDetailsMixin'

export default {
  components: {
    YbQueryPlan
  },
  mixins: [YbQueryDetailsMixin],
  props: {
    showCommand: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    rowset() {
      return this.editor && this.editor.rowset || {}
    },
    error() {
      return this.editor && this.editor.error || ''
    },
    errorMessage() {
      return editorHelper.formatErrorMessage(this.error)
    },
    hasRowset() {
      const { rowset } = this
      return rowset && (Number(rowset.execId) >= 0) && rowset.rows && rowset.rows.length
    },
    loading() {
      return this.editor && this.editor.databaseModelLoading
    }
  }
}
</script>

<style scoped>
label {
  @apply font-light;
}
</style>
