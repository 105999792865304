<template>
  <div class="yb-view" @click="click">
    <div class="yb-view-header pb-2 flex flex-row justify-end pr-2 pt-2">
      <yb-drop-down-button :title="translate('Choose how to highlight nodes in the query')" class="text-sm yb-border-content border rounded-sm">
        <template #label>
          <yb-icon class="yb-button-icon" icon="edit" />
          <span class="inline-block mr-2">Highlight By:</span><i>{{ findHighlightLabel(highlightBy) }}</i>
        </template>

        <template #default>
          <yb-drop-down-item
            v-for="(highlightType, index) in highlightTypes"
            :key="index"
            :label="highlightType.label"
            :active="highlightBy == highlightType.type"
            @click="highlightBy = highlightType.type"
          />
        </template>
      </yb-drop-down-button>
    </div>

    <div class="yb-view-content yb-view pr-2">
      <div class="yb-view-content overflow-y-auto">
        <yb-flame-chart ref="flame" class="mr-4" :data="plan" :self-value="true" @select="toggleSelected" />
      </div>

      <div class="yb-view-right w-80 2xl:w-96">
        <div class="flex flex-col justify-center p-3 overflow-hidden rounded border yb-border-content bg-yb-gray-faintest dark:bg-yb-gray-mud shadow-lg mr-0" style="min-height: 24rem" @wheel.stop="nop">
          <yb-node-tooltip v-if="selected" :o="selected" :nodes="nodes" />
          <div v-else class="text-lg text-center">
            Select query node to display details
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { sync } from 'vuex-pathify'

import { highlightTypes, findHighlight, findHighlightLabel } from '../query-details/queryNodeFilters'
import YbNodeTooltip from '../query-details/html-query-plan/YbNodeTooltip.vue'
import YbQueryDetailsMixin from '@/app/query-details/queryDetailsMixin'
import { translate } from '@/filters'
import * as functions from '@/util/functions'
import queryPlanBuilderService from '@/services/queryPlanBuilderService'

export default {
  mixins: [YbQueryDetailsMixin],
  components: {
    YbFlameChart: defineAsyncComponent(() => import('@/components/flame/YbFlame.vue')),
    YbNodeTooltip
  },
  props: {
  },
  data() {
    return {
      highlightTypes,
      selected: null
    }
  },
  computed: {
    highlightBy: sync('query-details/settings@highlightBy', false),
    nodes() {
      const { plan } = this
      const result = {}
      const gather = (n) => {
        result[n.node.id] = n.node
        n.children.forEach(gather)
      }
      gather(plan)
      return result
    },
    plan() {
      const { selectedQuery, highlightBy } = this
      if (!selectedQuery || !selectedQuery.query_plan_yb) {
        return {}
      }
      queryPlanBuilderService.analyzeTree(selectedQuery.query_plan_yb, highlightBy)
      const highlight = findHighlight(highlightBy)

      const convert = (n) => {
        const children = n.children ? n.children.map(convert) : []
        const valueLabel = !!highlight && !!highlight.filter ? highlight.filter(n.statValue) : n.statValue
        const name = `${n.label} [${valueLabel}]`
        const detail = []
        if (n.explain) {
          detail.push(n.explain)
        }
        if (n.columnNames) {
          detail.push(...n.columnNames)
        }
        if (n.expressions) {
          detail.push(...n.expressions)
        }
        const tooltip = `${n.label} [${n.id}]: ${valueLabel}\n\n${detail.join('\n')}`
        return {
          name,
          selfValue: n.statValue,
          value: n.statValue + functions.sum(children.map(c => c.value)),
          children,
          node: n,
          tooltip,
          libtype: n.className
        }
      }
      return convert(selectedQuery.query_plan_yb)
    }
  },
  methods: {
    translate,
    findHighlightLabel,
    nop() {},
    toggleSelected($event) {
      const selected = $event.node
      if (this.selected?.id === selected?.id) {
        this.selected = null
      } else {
        this.selected = selected
      }
    },
    click($event) {
      if (String($event?.target?.tagName).match(/DIV|SVG/i)) {
        this.$refs.flame.unselect()
        this.selected = null
      }
    }
  }
}
</script>
