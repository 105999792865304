<template>
  <div :ref="root" class="qp-root w-full h-full p-2 relative cursor-move" :class="classes" @click="unselect">
    <svg ref="svg" class="absolute top-0 left-0 transform-origin-00">
      <defs ref="defs">
        <marker
          id="smc-arrow"
          viewBox="0 0 10 10"
          :refX="orientationRefX"
          refY="5"
          markerWidth="6"
          markerHeight="6"
          orient="auto"
          markerUnits="strokeWidth"
        >
          <path d="M 10 0 L 0 5 L 10 10 z" fill="darkgray" />
        </marker>
      </defs>
      <g ref="g" />
    </svg>
    <div ref="domContainer" class="absolute top-0 left-0 transform-origin-00">
      <component :is="node.component" v-for="node in nodes" :key="node.id" v-bind="node.propsData" />
    </div>

    <transition name="slide-side">
      <div v-if="selected" class="node-details" @wheel.stop="nop">
        <yb-node-tooltip :o="selected" :nodes="nodes" />
      </div>
    </transition>
  </div>
</template>

<script>
import { defineComponent, markRaw } from 'vue'
import d3 from '@/lib/d3'
import debounce from 'debounce'
import sprintf from 'sprintf'

import queryPlanBuilderService from '@/services/queryPlanBuilderService'

import './queryPlan.css'
import YbNode from './YbNode.vue'
import YbNodeSmallIcon from './YbNodeSmallIcon.vue'
import YbNodeSmallClass from './YbNodeSmallClass.vue'
import YbNodeTooltip from './YbNodeTooltip.vue'
import { functions, dom, Logger } from '@/util'
import dagreModules from '@/lib/dagre-fixup'

// import dialog from './dialog.vue';

const nodeMap = {
  def: defineComponent(YbNode),
  distribute: defineComponent(YbNodeSmallIcon),
  distributeHash: defineComponent(YbNodeSmallIcon),
  distributeMap: defineComponent(YbNodeSmallIcon),
  distributeSortMerge: defineComponent(YbNodeSmallIcon),
  transposeColumnToRow: {
    component: defineComponent(YbNodeSmallClass),
    propsData: {
      iconClass: 'fa-retweet'
    }
  },
  transposeRowToColumn: {
    component: defineComponent(YbNodeSmallClass),
    propsData: {
      iconClass: 'fa-exchange'
    }
  }
}

const log = Logger.get('yb.query-plan')

// Load/await dagre and util.
let dagre, util;
(async function() {
  // Ensure we have util.
  const dagreResolvedModules = await dagreModules
  dagre = dagreResolvedModules.dagre
  util = dagreResolvedModules.util
})()

function intersectRect(rect, point) {
  if (rect.x === point.x && rect.y === point.y) {
    return point
  } else {
    try {
      return util.intersectRect(rect, point)
    } catch (e) {
      return { x: rect.x, y: rect.y }
    }
  }
}

export default {
  components: {
    YbNodeTooltip
  },
  props: {
    plan: [Object, String],
    stats: Object,
    orientation: String,
    highlightStatExpression: String,
    playbackRate: {
      type: Number,
      default: 500
    },
    setDimensions: Boolean
  },
  data() {
    return {
      selected: null,
      nodes: {},
      skipNotify: false
    }
  },
  computed: {
    classes() {
      return [`qp-orientation-${this.orientation}`]
    },
    empty() {
      return !this.$refs.domContainer || this.$refs.domContainer.childNodes.length === 0
    },
    orientationRefX() {
      return this.orientation === 'horizontal' ? 3 : 2
    }
  },
  watch: {
    plan(n, o) {
      this.maybeRender(n, o)
      const nid = n?.stats?.query_id
      const oid = o?.stats?.query_id
      if (!nid || !oid || nid !== oid) {
        this.unselect()
      } else {
        this.skipNotify = true
      }
    },
    stats(n, o) {
      this.maybeRender(n, o)
      if (!functions.isEqual(Object.keys(n), Object.keys(o))) {
        this.unselect()
      } else {
        this.skipNotify = true
        if (this.selected) {
          const d = n[this.selected.id]
          if (d) {
            this.selected.stats = functions.copyDeep(d)
          }
        }
      }
    },
    orientation(n, o) {
      this.maybeReRender(n, o)
      this.unselect()
    },
    highlightStatExpression(n, o) {
      this.maybeRender(n, o)
      this.unselect()
    }
  },
  created() {
    // This data is non-reactive on purpose.
    this.rendered = false
    this.root = null
  },
  beforeMount() {
    this.renderBounce = debounce(this.renderDOM.bind(this), 100)
  },
  mounted() {
    this.renderBounce()
  },
  methods: {
    updateTransform(event) {
      const transform = event.transform
      d3.select(this.$refs.svg)
        .attr('transform', transform)
      d3.select(this.$refs.domContainer)
        .style('transform', `translate(${transform.x}px, ${transform.y}px) scale(${transform.k}`)
        .style('transform-origin', '0 0')
    },
    renderDOM() {
      let start, end
      const widthAvailable = this.$el.offsetWidth
      log.debug('Element width: ' + widthAvailable)
      if (!this.plan) {
        log.debug('No plan; not rendering')
        return
      }
      if (widthAvailable <= 0) {
        log.debug('No size; not rendering')
        return
      }
      const firstTime = !this.rendered

      // Make the svg container.  This is first in dom to underlay everything else.
      const svgNode = d3.select(this.$refs.svg)
      const g = d3.select(this.$refs.g)
      if (firstTime) {
        // Configure the zoom
        if (!this.zoom) {
          this.zoom = d3.zoom()
            .scaleExtent([0.1, 8])
            .on('zoom', this.updateTransform.bind(this))
          const container = d3.select(this.$el)
          container
            .call(this.zoom)

          // Replace zoom wheel behavior, hooking only if ctrl + wheel or non-wheel.
          if (false) {
            const originalHandler = container.on('wheel.zoom')
            container.on('wheel.zoom', function(...args) {
              if (d3.event instanceof WheelEvent) {
                if (!d3.event.ctrlKey) {
                  return
                }
              }
              originalHandler.apply(this, args)
            })
            container.on('mousedown.zoom', null)
            container.on('mousemove.zoom', null)
            container.on('mouseup.zoom', null)
            container.on('dblclick.zoom', null)
            container.on('mousemove.queryplan', function() {
              // Recenter the zoom on the translated mouse coordinates so re-zooming centers on new mouse position properly.
              const position = d3.mouse(this)
              const translation = this.zoom.translate()
              position[0] += translation[0]
              position[1] += translation[1]
              this.zoom.center(position)
            })
          } else {
            container.on('dblclick.zoom', null)
          }
        }
      }

      // Tweak positioning.

      if (this.orientation === 'horizontal') {
        g.attr('transform', 'translate(0, 34)')
      } else {
        g.attr('transform', 'translate(0, 42)')
      }

      // Parse the json to tree, stats to array.
      const tree = typeof this.plan === 'string' ? queryPlanBuilderService.createTree(this.plan, this.stats, this.playbackRate) : this.plan

      // Create the dom container.
      const domContainer = d3.select(this.$refs.domContainer)

      // Create DOM from tree.
      start = +new Date()
      log.debug('Building dom for query plan')
      this.buildDOM(tree, domContainer, this.nodes)
      end = +new Date()
      log.debug(`Built dom for query plan in ${end - start}ms`)

      this.$nextTick(() => this.processDOM(tree, domContainer))
    },
    processDOM(tree, domContainer) {
      let start, end

      // Sew up dom.
      this.sewUpDOM(tree, domContainer)

      // Do statistical analytics.
      start = +new Date()
      queryPlanBuilderService.analyzeTree(tree, this.highlightStatExpression)
      end = +new Date()
      log.debug(`Analyzed tree for query plan in ${end - start}ms`)

      // log.debug('Tree:\n', JSON.stringify(tree, null, '  '));

      // Render the SVG portion of the drawing (arrows, etc.)
      this.renderSVG(tree)
    },
    sewUpDOM(node, parent) {
      // Find our subnode or create it.
      const me = parent.select(`div.qp-tr[node-id="${node.id}"]`)
      if (me && me.node()) {
        // Compile the node view.
        node.element = me.select('div > div[node-id]')
        node.element.node().__data__ = node // Associate for d3
        node.hideArrow = !!me.select('div[hide-arrow]').node()
      }

      // Do the children.
      node.children.forEach(c => this.sewUpDOM(c, parent))
    },
    buildDOM(node, parent, nodes) {
      // Store the node.
      const componentDef = nodeMap[node.nodeTypeShort] || nodeMap.def
      if (componentDef.component) {
        node.component = markRaw(componentDef.component)
        node.propsData = { o: node, nodes, ...componentDef.propsData }
      } else {
        node.component = markRaw(componentDef)
        node.propsData = { o: node, nodes }
      }
      nodes[node.id] = node

      // Do the children.
      node.children.forEach(c => this.buildDOM(c, parent, nodes))
    },
    renderSVG(tree) {
      let start, end
      const { zoom } = this
      const el = this.$el
      const { domContainer, svg } = this.$refs
      const svgNode = d3.select(svg)
      const svgDefs = d3.select(this.$refs.defs)
      const g = d3.select(this.$refs.g)

      // Signal start of render.
      this.$emit('onPreRenderPlan', el)

      // How to get a filter for path.
      function getFilter(level) {
        const key = 'smc-filter-' + level
        const def = svgDefs.select('#' + key)
        if (!def) {
          const filter = svgDefs.append('filter').attr('id', key)
          filter
            .append('feGaussianBlur')
            .attr('in', 'SourceAlpha')
            .attr('stdDeviation', level)
          filter
            .append('feOffset')
            .attr('dx', 2)
            .attr('dy', 2)
            .attr('result', 'offsetBlur')
          const merge = filter.append('feMerge')
          merge.append('feMergeNode')
          merge.append('feMergeNode').attr('in', 'SourceGraphic')
        }
        return dom.getClipPath(key)
      }

      // Create edges.
      const edges = g.selectAll('path.smc-plan-node-connector').data(functions.values(this.nodes), d => d.id)
      edges.exit().remove()
      edges
        .enter()
        .append('path')
        .attr('class', 'smc-plan-node-connector')
        .attr('stroke', 'darkgray')
        .attr('fill', 'none')
        .attr('marker-start', (node) => {
          const parent = this.nodes[node.parent]
          return !parent || parent.hideArrow ? 'none' : dom.getClipPath('smc-arrow')
        })
      edges
        .attr('stroke-width', function(node) {
          return this.getAttribute('stroke-width') || 1
        })
        .transition()
        .duration(parseFloat(this.playbackRate))
        .ease(d3.easeLinear)
        .attr('stroke-width', function(node) {
          return (1.0 + (node.statRatio || 0) * 4).toFixed(2)
        })

      const NODE_SPACING = 25
      const Y_OFFSET = 0 * 25
      const X_OFFSET = 0 * 25

      function spline(e) {
        const points = e.points.slice(0)
        if (this.orientation === 'horizontal') {
          points[0].x += 2
        } else {
          const source = intersectRect(e.source, points.length > 0 ? points[0] : e.source)
          const target = intersectRect(e.target, points.length > 0 ? points[points.length - 1] : e.target)
          points.unshift(source)
          points.push({ x: target.x, y: e.target.y })
        }
        log.debug(sprintf('edge: %s [%s] (%d,%d %dx%d) --> %s [%s] (%d,%d %dx%d): %s', e.source.label, e.source.id, e.source.x, e.source.y, e.source.width, e.source.height, e.target.label, e.target.id, e.target.x, e.target.y, e.target.width, e.target.height, JSON.stringify(points)))
        return d3.line()
          .x(function(d) {
            return X_OFFSET + d.x
          })
          .y(function(d) {
            return Y_OFFSET + d.y
          })
          .curve(d3.curveBundle)(points)
      }

      // Does the tree need a layout?
      if ((!tree.width && !tree.height) || !this.graph) {
        // Create the graph; compute layout.
        start = +new Date()
        const graph = new dagre.graphlib.Graph()
        graph.setGraph({
          nodesep: NODE_SPACING,
          edgesep: 10,
          ranksep: NODE_SPACING,
          rankdir: this.orientation === 'horizontal' ? 'LR' : 'TB',
          align: 'DL'
        })
        graph.setDefaultEdgeLabel(() => {});
        (function analyzePlan(node, parent, level) {
          node.width = node.element.node().clientWidth
          node.height = node.element.node().clientHeight
          node.rank = level
          graph.setNode(node.id, node)
          if (parent) {
            graph.setEdge(parent.id, node.id, {})
          }
          node.children.forEach((child) => {
            analyzePlan(child, node, level + 1)
          })
        })(tree, null, 0)
        dagre.layout(graph)
        end = +new Date()
        log.debug(`Calculated tree for query plan in ${end - start}ms`)
        this.graph = graph
      }

      // Create and position nodes.
      start = +new Date()
      d3.select(el)
        .selectAll('div.qp-tr > div > div[node-id]')
        .data(functions.values(this.nodes), d => d.id)
        .style('position', 'absolute')
        .style('left', function(d) {
          const r = X_OFFSET + d.x - d.width / 2 + 'px'
          log.debug('d [' + d.label + '/' + d.id + ']: ' + d.x + ',' + d.y + ' ' + d.width + 'x' + d.height + ' --> ' + r)
          return r
        })
        .style('top', function(d) {
          return Y_OFFSET + d.y + 'px'
        })
      end = +new Date()
      log.debug(`Positioned tree for query plan in ${end - start}ms`)

      // Position edges.
      g.selectAll('path.smc-plan-node-connector').attr('d', (node) => {
        const parent = this.nodes[node.parent]
        if (!parent) {
          return
        }
        const edge = this.graph.edge(parent.id, node.id)
        if (!edge) {
          return
        }
        return spline.call(this, {
          points: edge.points,
          source: parent,
          target: node
        })
      })

      // Calculate final dimensions.
      const maxY = functions.reduce(
        this.nodes,
        (max, node) => {
          return Math.max(max, node.y + node.height)
        },
        0
      )
      const maxX = functions.reduce(
        this.nodes,
        (max, node) => {
          return Math.max(max, node.x + node.width)
        },
        0
      )
      const dimensions = {
        width: maxX,
        height: maxY,
        clientWidth: maxX,
        clientHeight: maxY
      }

      // Calculate and set dimensions.
      {
        const { offsetWidth, offsetHeight } = el
        log.debug('Calculated dimensions, width: ' + dimensions.width + ', height: ' + dimensions.height)
        svgNode.attr('width', parseInt(dimensions.width))
        svgNode.attr('height', parseInt(dimensions.height))
        log.debug('Final width: ' + offsetWidth)
        log.debug('Final height: ' + offsetHeight)

        if (this.setDimensions) {
          domContainer.style.height = `${dimensions.height}px`
          domContainer.style.width = `${dimensions.width}px`
        } else {
          domContainer.style.height = 'auto'
          domContainer.style.width = 'auto'
        }
      }

      // Add node click to zoom interaction.
      const that = this
      function clicked(event, d) {
        if (that.selected && that.selected.id === d.id) {
          that.unselect()
          return
        }
        const currentTransform = d3.zoomTransform(el)
        const { offsetWidth, offsetHeight } = el
        const position = d3.pointer(event, svgNode.node())
        const container = d3.select(el)
        container
          .transition()
          .duration(750)
          .call(
            zoom.transform,
            d3.zoomIdentity
              .translate(offsetWidth / 3, offsetHeight / 3)
              .scale(1)
              .translate(-position[0], -position[1])
          )
        event.preventDefault()
        event.stopPropagation()

        // Set selection.
        const selected = functions.copyDeep(d)
        delete selected.element
        delete selected.children
        that.priorTransform = null
        that.unselect()
        that.selected = selected
        that.nodes[that.selected.id].selected = true
        if (currentTransform.k !== 1) {
          that.priorTransform = currentTransform
        }
      }
      d3.select(el)
        .selectAll('div.qp-tr > div > div[node-id]')
        .on('click', clicked)

      // Signal done.
      if (!this.skipNotify) {
        this.$emit('onPostRenderPlan', this.$el, dimensions)
      }
      this.skipNotify = false
      this.rendered = true
    },
    maybeRender(newValue, oldValue) {
      if (!this.rendered || newValue !== oldValue) {
        this.renderBounce()
      }
    },
    maybeReRender(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (!this.empty) {
          this.graph = null
          this.renderBounce()
        }
      }
    },
    zoomDelta(deltaK) {
      const transform = d3.zoomTransform(this.$el)
      const { zoom } = this
      if (!!transform && !!zoom) {
        zoom.scaleTo(d3.select(this.$el).transition().duration(750), transform.k + deltaK, [transform.x, transform.y])
        log.debug('Zoom delta', deltaK, 'to transform', d3.zoomTransform(this.$el))
      }
    },
    zoomReset(k, immediate) {
      const { zoom } = this
      if (zoom) {
        d3.select(this.$el).transition().duration(immediate ? 0 : 750).call(zoom.transform, d3.zoomIdentity.scale(k))
      }
    },
    currentZoom() {
      return d3.zoomTransform(this.$el).k
    },
    unselect() {
      if (this.selected) {
        this.nodes[this.selected.id].selected = false
      }
      const { zoom } = this
      if (!!this.priorTransform && !!zoom) {
        d3.select(this.$el).transition().duration(750).call(zoom.transform, this.priorTransform)
        this.priorTransform = null
      }
      this.selected = null
    },
    nop() {

    },
    rerender() {
      this.graph = null
      this.renderBounce()
    }
  }
}
</script>

<style scoped lang="postcss">

.node-details {
  @apply absolute top-16 right-4 bottom-4 w-1/2 3xl:w-1/3 p-3 overflow-hidden rounded border yb-border-content bg-yb-gray-faintest dark:bg-yb-gray-mud shadow-lg;
}
</style>
