<template>
  <div class="qp-stats">
    <table class="table w-full">
      <tr>
        <td width="150px" />
        <td />
      </tr>

      <tr v-show="o.stats.io_spill_read_bytes || o.stats.io_spill_write_bytes || o.stats.io_spill_space_bytes" class="font-normal">
        <td colspan="2">
          <div v-if="o.stats.io_spill_read_bytes > 0" class="qp-stat-spill-label">
            <yb-icon class="yb-button-icon-lg" icon="warning" /><translate>Used {{ bytes(o.stats.io_spill_read_bytes) }} of temporary space for reads</translate>
          </div>
          <div v-if="o.stats.io_spill_write_bytes > 0" class="qp-stat-spill-label">
            <yb-icon class="yb-button-icon-lg" icon="warning" /><translate>Used {{ bytes(o.stats.io_spill_write_bytes) }} of temporary space for writes</translate>
          </div>
          <div v-if="(o.stats.io_spill_read_bytes > 0 || o.stats.io_spill_write_bytes > 0) && o.stats.io_spill_space_bytes > 0" class="qp-stat-spill-label">
            <yb-icon class="yb-button-icon-lg" icon="warning" /><translate>Used {{ bytes(o.stats.io_spill_space_bytes) }} of temporary space</translate>
          </div>
          <div v-if="(o.stats.runtime_ms > 60*1000 && o.stats.rows_actual == 0)" class="qp-stat-spill-label">
            <yb-icon class="yb-button-icon-lg" icon="stop" /><translate>Duration of {{ humanizeMs(o.stats.runtime_ms) }} without any output rows</translate>
          </div>

          <div class="h-4 w-4" />
        </td>
      </tr>

      <tr v-show="o.tableNames && o.tableNames.length > 0">
        <td colspan="2">
          <yb-disclosure label="Table">
            <a v-for="(table, index) in o.tableNames" :key="index" class="ml-8" href="" @click="openTable(table)">
              <span>{{ table }}</span>
            </a>
          </yb-disclosure>
        </td>
      </tr>

      <tr v-show="o.expressions && o.expressions.length > 0">
        <td colspan="2">
          <yb-disclosure label="Expressions">
            <div v-for="(expression, index) in o.expressions" :key="index" class="font-normal ml-8">
              {{ expression }}
            </div>
          </yb-disclosure>
        </td>
      </tr>

      <tr v-show="o.explain">
        <td colspan="2">
          <yb-disclosure label="Details">
            <div class="font-normal ml-8">
              {{ o.explain }}
            </div>
          </yb-disclosure>
        </td>
      </tr>

      <tr v-show="o.type">
        <td translate>
          Type:
        </td>
        <td>{{ o.type }}</td>
      </tr>

      <tr v-show="o.qual">
        <td translate>
          Join Qualifier:
        </td>
        <td>{{ o.qual }}</td>
      </tr>

      <tr v-show="o.columnNames && o.columnNames.length > 0">
        <td colspan="2">
          <yb-disclosure label="Columns">
            <div v-for="(c, index) in o.columnNames" :key="index" class="font-normal ml-8">
              {{ c }}
            </div>
          </yb-disclosure>
        </td>
      </tr>

      <tr v-show="o.hash && o.hash.length > 0">
        <td translate>
          Hash:
        </td>
        <td>
          <div v-for="(h, index) in o.hash" :key="index">
            {{ h }}
          </div>
        </td>
      </tr>

      <tr v-show="o.keys && o.keys.length > 0">
        <td translate>
          Keys:
        </td>
        <td>
          <div v-for="(k, index) in o.keys" :key="index">
            {{ k }}
          </div>
        </td>
      </tr>

      <tr v-show="o.constraints && o.constraints.length > 0">
        <td translate>
          Constraints:
        </td>
        <td>
          <div v-for="(c, index) in o.constraints" :key="index">
            {{ c }}
          </div>
        </td>
      </tr>

      <tr v-show="o.bloom">
        <td translate>
          Bloom:
        </td>
        <td>
          <div>
            <yb-node-link :o="findLink(o.bloom)" />
          </div>
        </td>
      </tr>
      <tr v-if="o.distType">
        <td colspan="2">
          <yb-disclosure label="Distribution">
            <div v-for="(c, index) in o.distType.split(/,/g)" :key="index" class="font-normal ml-8">
              {{ c }}
            </div>
          </yb-disclosure>
        </td>
      </tr>

      <tr v-show="(o.filterVector && o.filterVector.length) || (o.filterScalar && o.filterScalar.length)">
        <td translate>
          Filter:
        </td>
        <td>
          <div v-for="(c, index) in o.filterVector" :key="index">
            <yb-node-link :o="findLink(c)" />
          </div>
          <div v-for="(c, index) in o.filterScalar" :key="index">
            <yb-node-link :o="findLink(c)" />
          </div>
        </td>
      </tr>

      <tr v-if="o.haveStats">
        <td colspan="2">
          <yb-disclosure label="Statistics" initially-disclosed>
            <table class="ml-8">
              <tr v-for="(id, index) in o.orderedStatNames" v-show="o.stats[id]" :key="index">
                <td nowrap class="font-normal pr-2">
                  {{ translate(id) }}
                </td>
                <td>{{ smcNiceFilter(o.stats[id], id) }}</td>
              </tr>
            </table>
          </yb-disclosure>
        </td>
      </tr>

      <template v-if="$toggles.queryDevStats && o.haveDetailStats && o.orderedDetailStatNames && o.orderedDetailStatNames.length > 0">
        <tr>
          <td colspan="2" translate>
            <br>
          </td>
        </tr>
        <tr>
          <td colspan="2" translate class="font-semibold">
            Details:
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <table class="font-light text-sm w-full table-detail">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Sum</th>
                  <th>Count</th>
                  <th>Minimum</th>
                  <th>Maximum</th>
                  <th>Average</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(stat, index) in o.orderedDetailStatNames" :key="index">
                  <td class="!font-light" nowrap>
                    {{ stat }}
                  </td>
                  <td nowrap>
                    {{ number((o.detailStats[stat] && o.detailStats[stat].sum || 0)) }}
                  </td>
                  <td nowrap>
                    {{ number((o.detailStats[stat] && o.detailStats[stat].count || 0)) }}
                  </td>
                  <td nowrap>
                    {{ number((o.detailStats[stat] && o.detailStats[stat].min || 0)) }}
                  </td>
                  <td nowrap>
                    {{ number((o.detailStats[stat] && o.detailStats[stat].max || 0)) }}
                  </td>
                  <td nowrap>
                    {{ number((o.detailStats[stat] && o.detailStats[stat].avg || 0)) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </template>

      <tr v-if="$toggles.queryDevStats && meta">
        <td>DEBUG:</td>
      </tr>
      <tr v-if="$toggles.queryDevStats && meta">
        <td colspan="2">
          <table>
            <tr>
              <td>Stat Value</td>
              <td>{{ number(o.statValue) }}</td>
            </tr>
            <tr>
              <td>Stat Ratio</td>
              <td>{{ number(o.statRatio) }}</td>
            </tr>
            <tr>
              <td>Min Ticks</td>
              <td>{{ number(o.stats.ticks.min) }}</td>
            </tr>
            <tr>
              <td>Max Ticks</td>
              <td>{{ number(o.stats.ticks.max) }}</td>
            </tr>
            <tr>
              <td>Avg Ticks</td>
              <td>{{ number(o.stats.ticks.avg) }}</td>
            </tr>
            <tr>
              <td>Sum Ticks</td>
              <td>{{ number(o.stats.ticks.sum) }}</td>
            </tr>
            <tr>
              <td>Total Ticks</td>
              <td>{{ number(executionTicks) }}</td>
            </tr>
            <tr>
              <td>Ticks Ratio</td>
              <td>{{ number(((o.stats.ticks.avg / executionTicks) * 100), 1) }} %</td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import YbNodeLink from './YbNodeLink.vue'
import nodeMixin from './nodeMixin'
import { join, smcNiceFilter, translate, number, bytes, humanizeMs } from '@/filters'

export default {
  components: {
    YbNodeLink
  },
  mixins: [nodeMixin],
  props: {
    meta: Boolean
  },
  methods: {
    join,
    smcNiceFilter,
    translate,
    number,
    bytes,
    humanizeMs,
    openTable(table) {
      throw new Error('Open table has not been implemented')
    },
    findLink(linkish) {
      let result;
      [/(?:.*)Node_(\d*)\).*/, /.*node=(\d*).*/].forEach((re) => {
        const id = re.exec(linkish)
        if (id) {
          result = this.nodes[parseInt(id[1], 10)]
        }
      })
      return result
    }
  }
}
</script>

<style scoped lang="postcss">
.yb-disclosure {
  @apply mb-4;
}

.table-detail th {
  @apply yb-bg-pane text-left font-medium py-1;
}

.table-detail th:first-child {
  @apply pl-1;
}

</style>
