<template>
  <div
    ref="fullscreen"
    v-resize
    class="yb-view select-none yb-bg-fullscreen"
    :class="{'p-2' : fullscreen}"
    yb-tooltip-target
    @resize="resizeit"
    @onfullscreenchange="fullscreenChanged"
  >
    <div class="yb-view-header pb-2 flex flex-row space-x-2 items-center justify-end">
      <yb-button-group
        v-model="orientation"
        label="Orientation:"
        class="text-sm"
        :vertical="false"
        btn-down="btn-yellow"
        type="id"
        :options="[
          {
            id: 'horizontal',
            label: 'Horizontal'
          },
          {
            id: 'vertical',
            label: 'Vertical'
          },
        ]"
      />

      <yb-drop-down-button :title="translate('Choose how to highlight nodes in the query')" class="text-sm yb-border-content border rounded-sm">
        <template #label>
          <yb-icon class="yb-button-icon" icon="edit" />
          <span class="inline-block mr-2">Highlight By:</span><i>{{ findHighlightLabel(highlightBy) }}</i>
        </template>

        <template #default>
          <yb-drop-down-item
            v-for="(highlightType, index) in highlightTypes"
            :key="index"
            :label="highlightType.label"
            :active="highlightBy == highlightType.type"
            @click="setHighlightBy(highlightType.type)"
          />
        </template>
      </yb-drop-down-button>

      <div v-show="!hideFullscreen" class="yb-button yb-border-content border font-light" @click="toggle">
        <yb-icon class="yb-button-icon stroke-current" icon="fullscreen" />
        Fullscreen
      </div>
    </div>

    <div class="yb-view-content yb-query-plan-outer" @dblclick="zoomReset(true, false)">
      <yb-query-plan
        ref="yb-query-plan-main"
        class="yb-query-plan-main"
        :plan="selectedQuery.query_plan_yb"
        :stats="selectedQuery.query_plan_stats"
        :highlight-stat-expression="highlightBy"
        :orientation="orientation"
        :set-dimensions="true"
        @on-pre-render-plann="onPreRenderPlan"
        @on-post-render-plan="onPostRenderPlan"
      />
      <div class="yb-query-plan-zoom flex rounded-lg bg-white p-2 border yb-border-content" @dblclick.stop="">
        <span class="text-xs mr-2" translate>Zoom:</span>
        <div class="yb-button-xs inline-block" :disabled="zoomInDisabled()" :title="translate('Zoom in')" @click="updateZoom(0.1)">
          <yb-icon class="yb-button-icon-md stroke-current" icon="plus" />
        </div>
        <div class="yb-button-xs inline-block" :disabled="zoomOutDisabled()" :title="translate('Zoom out')" @click="updateZoom(-0.1)">
          <yb-icon class="yb-button-icon-md stroke-current" icon="minus" />
        </div>
        <div class="yb-button-xs inline-block" :title="translate('Zoom reset')" @click="zoomReset(true)">
          <yb-icon class="yb-button-icon-md stroke-current" icon="refresh" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sync } from 'vuex-pathify'
import debounce from 'debounce'

import YbQueryPlan from './html-query-plan/YbQueryPlan.vue'
import { highlightTypes, findHighlightLabel } from './queryNodeFilters'
import { Logger, functions } from '@/util'
import { translate } from '@/filters'

const log = Logger.get('yb.plan')

function prettify(s) {
  // Assuming "s" is JSON, read it in, write it out with indentation
  if (typeof s === 'string') {
    const obj = JSON.parse(s)
    return JSON.stringify(obj, null, 2)
  } else {
    return JSON.stringify(s, null, 2)
  }
}

export default {
  components: {
    YbQueryPlan
  },
  props: {
    selectedQuery: Object,
    hideFullscreen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      first: true,
      fullscreen: false,
      redraw: 0,
      initScale: 1,
      qp: null,
      highlightTypes
    }
  },
  computed: {
    orientation: sync('query-details/settings@orientation', false),
    highlightBy: sync('query-details/settings@highlightBy', false)
  },
  watch: {
    settings: {
      deep: true,
      handler(settings) {
        this.$store.set('query-details/settings', functions.copy(settings))
      }
    }
  },
  mounted() {
    this.qp = this.$refs['yb-query-plan-main']
  },
  beforeMount() {
    this.fullScreenListener = this.fullscreenChanged.bind(this)
    document.addEventListener('fullscreenchange', this.fullScreenListener)

    this.resize = debounce(this.resizeImpl.bind(this), 500)
  },
  beforeUnmount() {
    if (this.fullScreenListener) {
      document.removeEventListener('fullscreenchange', this.fullScreenListener)
      this.fullScreenListener = null
    }
  },
  methods: {
    translate,
    toggle() {
      this.fullscreen = !this.fullscreen
      if (this.fullscreen) {
        this.$refs.fullscreen.requestFullscreen()
        this.zoomReset(false, false)
      } else {
        document.exitFullscreen()
        this.zoomReset(true, false)
      }
    },
    fullscreenChanged(evt) {
      this.fullscreen = !!document.fullscreenElement
    },
    findHighlightLabel,
    setHighlightBy(type) {
      this.highlightBy = type
    },

    // Handlng zoom.
    zoomInDisabled() {
      return !this.qp || this.qp.currentZoom() >= 2
    },
    zoomOutDisabled() {
      return !this.qp || this.qp.currentZoom() <= 0.1
    },
    updateZoom(delta) {
      this.qp && this.qp.zoomDelta(delta)
    },
    zoomReset(retain, immediate) {
      this.qp && this.qp.zoomReset(retain ? this.initScale : 1, immediate)
    },
    onPreRenderPlan(element) {
    },
    onPostRenderPlan(element, dimensions) {
      this.dimensions = dimensions
      const availWidth = this.$el.offsetWidth
      const availHeight = this.$el.offsetHeight
      log.debug('Post render: available width: ', availWidth, 'available height: ', availHeight, ', dimensions: ', JSON.stringify(dimensions, null, 1))

      // If not fullscreen, then scale.
      if (!this.fullscreen) {
        if (dimensions.clientWidth > dimensions.clientHeight) {
          this.initScale = Math.min(1, availWidth * 0.9 / dimensions.clientWidth)
        } else {
          this.initScale = Math.min(1, availHeight * 0.9 / dimensions.clientHeight)
        }
        this.zoomReset(true, true)

        // Handle fullscreen by just consuming height remaining
      } else {
        const remainingHeight = availHeight - element.parentNode.offsetTop
        element.style.height = `${remainingHeight}px`
        this.zoomReset(false, true)
      }
    },

    resizeit() {
      // Ignore the first invocation of resize handler.
      if (this.first) {
        this.first = false
        return
      }
      this.resize()
    },

    resizeImpl() {
      this.qp && this.qp.rerender()
    }
  }
}
</script>

<style scoped lang="postcss">

.yb-query-plan-outer {
  @apply relative rounded border yb-border-content overflow-hidden mb-2 p-8;
}

.yb-query-plan-outer svg {
  @apply cursor-move;
}

.yb-query-plan-zoom {
  @apply absolute rounded p-2 bg-yb-gray-faintest dark:bg-yb-gray-mud shadow-lg;
  top: 1em;
  right: 1em;
  z-index: 3;
}
</style>
