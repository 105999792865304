<template>
  <div class="yb-view-content yb-view relative">
    <div class="yb-view-header flex flex-row justify-end pr-2 pt-2">
      <yb-button
        v-if="false"
        class="yb-button"
        action-link
        :disabled="selectedRow === null"
        icon="nav_options"
        label="Details"
        data-test-id="query-details"
        @click="openNodeDetails()"
      />

      <yb-drop-down-button :title="translate('Choose how to highlight nodes in the query')" class="text-sm yb-border-content border rounded-sm">
        <template #label>
          <yb-icon class="yb-button-icon" icon="edit" />
          <span class="inline-block mr-2">Highlight By:</span><i>{{ findHighlightLabel(highlightBy) }}</i>
        </template>

        <template #default>
          <yb-drop-down-item
            v-for="(highlightType, index) in highlightTypes"
            :key="index"
            :label="highlightType.label"
            :active="highlightBy == highlightType.type"
            @click="highlightBy = highlightType.type"
          />
        </template>
      </yb-drop-down-button>
    </div>

    <div class="yb-view-content yb-view py-2 pr-2">
      <yb-grid
        ref="grid"
        class="yb-view-content"
        :rows="model"
        :row-height="44"
        :column-defs="columnDefs"
        column-id="node_Id"
        no-rows-to-show="No query plan statistics to display"
        @ready="ready"
        @select="selectRow"
        @dblclick="selectRowAndOpenDetails"
      />

      <div v-if="showNodeDetails" class="yb-view-right w-80 2xl:w-96 relative">
        <div class="h-full flex flex-col justify-center p-3 overflow-hidden rounded-sm border yb-border-content bg-yb-gray-faintest dark:bg-yb-gray-mud ml-2">
          <yb-node-tooltip :o="selectedRow" :nodes="nodes" />
        </div>

        <yb-close small @click="showNodeDetails = false" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { sync } from 'vuex-pathify'

import QueryDetailsGridNodeRenderer from './QueryDetailsGridNodeRenderer.vue'
import { translate } from '@/filters'
import queryPlanBuilderService from '@/services/queryPlanBuilderService'
import * as YbGridCellRenderers from '@/components/YbGridCellRenderers'
import YbQueryDetailsMixin from '@/app/query-details/queryDetailsMixin'
import { highlightTypes, findHighlightLabel } from '@/app/query-details/queryNodeFilters'
import YbNodeTooltip from '@/app/query-details/html-query-plan/YbNodeTooltip.vue'

const YbQueryDetailsGridNodeRenderer = defineComponent(QueryDetailsGridNodeRenderer)

export default {
  components: {
    YbNodeTooltip
  },
  mixins: [YbQueryDetailsMixin],
  data() {
    return {
      columnDefs: [
        {
          headerName: 'Node',
          cellClass: 'center',
          field: 'nodeIndex',
          sortable: true,
          filter: false,
          flex: 10,
          minWidth: 300,
          cellRenderer: YbQueryDetailsGridNodeRenderer
        },
        /*
        {
          headerName: 'Meta',
          cellClass: 'center',
          valueGetter: params => {
            return `${params?.data?.nodeIndex}: ${params?.data?.level}`;
          },
          minWidth: 125,
        },
        */
        {
          headerName: 'Fraction',
          cellClass: 'center',
          field: 'statRatio',
          minWidth: 125,
          sortable: true,
          cellRenderer: YbGridCellRenderers.YbGridPercentBarCellRenderer
        },
        {
          headerName: 'Rows',
          cellClass: 'center',
          field: 'stats.rows_actual',
          minWidth: 125,
          sortable: true,
          cellRenderer: YbGridCellRenderers.YbGridHumanizeNumber
        },
        {
          headerName: 'Active',
          cellClass: 'center',
          field: 'stats.runtime_ms',
          minWidth: 125,
          sortable: true,
          cellRenderer: YbGridCellRenderers.YbGridDurationCellRenderer
        },
        {
          headerName: 'Memory Used',
          cellClass: 'center',
          field: 'stats.memory_actual_bytes',
          minWidth: 125,
          sortable: true,
          cellRenderer: YbGridCellRenderers.YbGridBytes
        },
        {
          headerName: 'Read I/O',
          cellClass: 'center',
          minWidth: 125,
          sortable: true,
          valueGetter: (params) => {
            let sum = 0
            if (typeof params?.data?.stats?.io_read_bytes === 'number' && params?.data?.stats?.io_read_bytes > 0) {
              sum += params.data.stats.io_read_bytes
            }
            if (typeof params?.data?.stats?.io_spill_read_bytes === 'number' && params?.data?.stats?.io_spill_read_bytes > 0) {
              sum += params.data.stats.io_spill_read_bytes
            }
            return sum
          },
          cellRenderer: YbGridCellRenderers.YbGridBytes
        },
        {
          headerName: 'Write I/O',
          cellClass: 'center',
          minWidth: 125,
          sortable: true,
          valueGetter: (params) => {
            let sum = 0
            if (typeof params?.data?.stats?.io_write_bytes === 'number' && params?.data?.stats?.io_write_bytes > 0) {
              sum += params.data.io_write_bytes
            }
            if (typeof params?.data?.stats?.io_spill_write_bytes === 'number' && params?.data?.stats?.io_spill_write_bytes > 0) {
              sum += params.data.stats.io_spill_write_bytes
            }
            return sum
          },
          cellRenderer: YbGridCellRenderers.YbGridBytes
        },
        {
          headerName: 'Network I/O',
          cellClass: 'center',
          field: 'stats.io_network_bytes',
          minWidth: 125,
          sortable: true,
          cellRenderer: YbGridCellRenderers.YbGridBytes
        }
      ],
      highlightTypes,
      selectedRow: null,
      showNodeDetails: false,
      nodes: {}
    }
  },
  computed: {
    model() {
      const { selectedQuery, highlightBy } = this
      if (!selectedQuery || !selectedQuery.query_plan_yb) {
        return []
      }
      queryPlanBuilderService.analyzeTree(selectedQuery.query_plan_yb, highlightBy)
      return selectedQuery.detail?.nodes || []
    },
    highlightBy: sync('query-details/settings@highlightBy', false)
  },
  watch: {
    highlightBy(_) {
      this.$nextTick(() => this.$refs.grid.reset())
    },
    showNodeDetails(_) {
      if (!_) {
        this.selectedRow = null
      }
    }
  },
  methods: {
    ready() {
      this.$refs.grid.gridColumnApi.applyColumnState({
        state: [
          {
            colId: 'index',
            sort: 'asc'
          }
        ],
        defaultState: { sort: null }
      })
    },
    openNodeDetails() {
      this.showNodeDetails = !this.showNodeDetails
    },
    selectRowAndOpenDetails(rows) {
      if (Array.isArray(rows)) {
        if (rows.length) {
          this.selectedRow = rows[0]
          this.showNodeDetails = true
        } else {
          this.selectedRow = null
          this.showNodeDetails = false
        }
      }
    },
    selectRow(rows) {
      if (rows.length) {
        this.selectedRow = rows[0]
      } else {
        this.selectedRow = null
        this.showNodeDetails = false
      }
    },
    translate,
    findHighlightLabel
  }
}
</script>
