<template>
  <div v-if="historyLoading" class="flex items-center justify-center h-full">
    <yb-loading />
  </div>
  <div v-else>
    <p class="font-semibold mt-2">
      Summary
    </p>
    <hr class="mb-4">

    <div>
      <div class="flex items-start">
        <div class="mr-16">
          <table class="table-auto">
            <tr>
              <td class="pr-8 pb-2 font-medium">
                First Run
              </td>
              <td class="pb-2">
                {{ formatDateTimeMs(querySummary.first_run) }}
              </td>
            </tr>
            <tr>
              <td class="pr-8 pb-2 font-medium">
                Last Run
              </td>
              <td class="pb-2">
                {{ formatDateTimeMs(querySummary.last_run) }}
              </td>
            </tr>
          </table>
        </div>
        <div>
          <table class="table-auto">
            <tr>
              <td class="pr-8 pb-2 font-medium">
                Execution Count
              </td>
              <td class="pb-2">
                {{ number(querySummary.run_count) }}
              </td>
            </tr>
            <tr>
              <td class="pr-8 pb-2 font-medium">
                Total Execution Time
              </td>
              <td class="pb-2">
                {{ humanizeMs(querySummary.sum_time / 1, true) }}
              </td>
            </tr>
            <tr>
              <td class="pr-8 pb-2 font-medium">
                Longest Execution Time
              </td>
              <td class="pb-2">
                {{ humanizeMs(querySummary.max_time / 1, true) }}
              </td>
            </tr>
            <tr>
              <td class="pr-8 pb-2 font-medium">
                Shortest Execution Time
              </td>
              <td class="pb-2">
                {{ humanizeMs(querySummary.min_time / 1, true) }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <p class="mt-6">
      <span class="font-semibold">Executions</span> (last 10)
    </p>
    <hr class="mb-4">

    <div>
      <table class="table-auto">
        <tr>
          <td class="pr-8 pb-2 font-medium">
            Last Executed
          </td>
          <td class="pb-2 font-medium">
            Duration
          </td>
          <td class="pb-2" />
        </tr>

        <tr v-for="(row, index) of queryRows" :key="index">
          <td class="pr-8 pb-2">
            {{ formatDateTime(row['submit_time']) }}
          </td>
          <td class="pb-2 w-96 pr-4">
            <div
              v-tooltip="((row.query_id != queryId) ? 'Display details for query (': 'Displaying this query (') + row.query_id + ')'"
              class="bg-yb-brand-primary p-3 rounded"
              :class="(row.query_id != queryId) && 'cursor-pointer'"
              @click="(row.query_id != queryId) && $emit('query', row.query_id)"
            />
          </td>
          <td class="pb-2">
            <div class="flex flex-row flex-nowrap items-center space-x-2">
              <div>{{ humanizeMs(row['total_ms']) }}</div>
              <yb-icon v-if="row.query_id == queryId" v-tooltip="'This duration displayed for the current query (' + queryId + ')'" icon="info" class="yb-button-icon" />
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { databaseObjectService, errorHandler } from '@/services'
import Instance from '@/models/Instance'
import {
  humanizeMs,
  formatDateTime,
  formatDateTimeMs,
  number
} from '@/filters'

export default {
  props: {
    queryId: {
      type: [Number, null],
      required: true
    },
    instanceId: {
      type: String,
      required: true
    },
    database: {
      type: String,
      required: true
    },
    planId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      historyLoading: false,
      queryRows: [],
      querySummary: {}
    }
  },
  computed: {
    instance () {
      return Instance.query().where('id', this.instanceId).first()
    }
  },
  async created() {
    this.historyLoading = true
    try {
      const queryRowsSql = `select query_id, submit_time, total_ms from sys.log_query where plan_id = '${this.planId}' order by submit_time desc limit 10`
      const querySummarySql =
`select
  min(submit_time) as "first_run",
  max(submit_time) as "last_run",
  min(run_ms) as "min_time",
  max(run_ms) as "max_time",
  sum(run_ms) as "sum_time",
  count(*) as "run_count"
from sys.log_query
where plan_id = '${this.planId}'`
      const [queryRows, querySummary] = await Promise.all([
        databaseObjectService.sql(this.instance?.id, this.database, queryRowsSql),
        databaseObjectService.sql(this.instance?.id, this.database, querySummarySql)
      ])
      this.queryRows = queryRows.rows
      this.querySummary = querySummary.rows[0] || {}
    } catch (e) {
      errorHandler.thrown(e, 'Could not retrieve query history.')
    } finally {
      this.historyLoading = false
    }
  },
  methods: {
    formatDateTime,
    formatDateTimeMs,
    humanizeMs,
    number
  }
}
</script>
