<template>
  <div class="flex flex-row flex-wrap">
    <table class="yb-query-utilization table text-sm ml-6">
      <thead>
        <tr>
          <td colspan="3">
            <div class="yb-text-label">
              <yb-icon class="yb-symbol-icon-md mr-1" icon="memory" />Memory Usage
            </div>
          </td>
        </tr>
        <tr>
          <th style="width: 230px" />
          <th class="underline" style="text-align: center;white-space: nowrap">
            Memory Consumed
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in selectedQuery.detail.memStats" :key="index">
          <td>
            <div class="yb-cpu-stat-label" translate>
              Worker Node:&nbsp;{{ index + 1 }}
            </div>
            <div class="yb-cpu-stat-id">
              [{{ item.chassisId }}]  {{ item.workerId }}
            </div>
          </td>

          <td class="yb-cpu-stat-chart">
            <div style="margin: 8px 8px;">
              <div style="width: 80px; display: table-cell; white-space: nowrap" class="text-sm">
                {{ bytes(item.stats.highWaterBytes) }}&nbsp;
              </div>
              <div style="width: 80px; display: table-cell; vertical-align: middle; background-color: lightgray">
                <div class="bg-yb-brand-primary" style="height: 12px;" :style="{ width: `${item.stats.stat}%` }" />
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td><hr></td>
          <td><hr></td>
        </tr>
        <tr>
          <td class="yb-cpu-stat-label" translate>
            Total:
          </td>
          <td>
            &nbsp;<strong>{{ bytes(selectedQuery.detail.totalMem) }}</strong>
          </td>
        </tr>

        <tr>
          <td class="yb-cpu-stat-label" translate>
            Average:
          </td>
          <td class="yb-cpu-stat-chart">
            &nbsp;<strong>{{ bytes(selectedQuery.memory_bytes) }}</strong>
          </td>
        </tr>

        <tr>
          <td class="yb-cpu-stat-label" translate>
            Longest Worker:
          </td>
          <td class="yb-cpu-stat-chart">
            &nbsp;<strong>{{ bytes(selectedQuery.memory_bytes_max) }}</strong>
          </td>
        </tr>
      </tbody>
    </table>

    <table class="yb-query-utilization table text-sm ml-6">
      <thead>
        <tr>
          <td colspan="3">
            <div class="yb-text-label">
              <yb-icon class="yb-symbol-icon-md mr-1" icon="cpu" />CPU Usage
            </div>
          </td>
        </tr>
        <tr>
          <th style="width: 230px" />
          <th class="underline" style="text-align: center;padding: 0px 15px;white-space: nowrap" translate>
            CPU Utilization
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in selectedQuery.detail.cpuStats" :key="index">
          <td>
            <div class="yb-cpu-stat-label" translate>
              Worker Node:&nbsp;{{ index }}
            </div>
            <div class="yb-cpu-stat-id">
              [{{ item.chassisId }}]  {{ item.workerId }}
            </div>
          </td>

          <td v-for="(stat, index2) in item.stats" :key="index2" class="yb-cpu-stat-chart">
            <div style="margin: 8px 8px;">
              <div style="width: 34px; display: table-cell" class="text-sm">
                {{ stat }}%&nbsp;
              </div>
              <div style="width: 80px; display: table-cell; vertical-align: middle; background-color: lightgray">
                <div class="bg-yb-brand-primary" style="height: 12px;" :style="{ width: `${stat}%` }" />
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td><hr></td>
          <td><hr></td>
        </tr>

        <tr>
          <td class="yb-cpu-stat-label" translate>
            Maximum:
          </td>
          <td class="yb-cpu-stat-chart">
            &nbsp;<strong>{{ number(selectedQuery.cpu_percent_max, 0) }}%</strong>
          </td>
        </tr>
        <tr v-show="selectedQuery.cpu_percent_avg">
          <td class="yb-cpu-stat-label" translate>
            Average:
          </td>
          <td class="yb-cpu-stat-chart">
            &nbsp;<strong>{{ number(selectedQuery.cpu_percent_avg, 0) }}%</strong>
          </td>
        </tr>

        <tr>
          <td class="yb-cpu-stat-label" translate>
            Longest Worker:
          </td>
          <td class="yb-cpu-stat-chart">
            &nbsp;<strong>{{ number(selectedQuery.cpu_percent, 0) }}%</strong>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import YbResultsMixin from './ResultsMixin'
import { bytes, number } from '@/filters'

export default {
  mixins: [YbResultsMixin],
  methods: {
    bytes,
    number
  }
}
</script>

<style scoped>

.yb-query-utilization td,
.yb-query-utilization th {
  padding-right: 10px;
  padding-bottom: 3px;
}

.yb-cpu-stat-label {
  @apply font-semibold text-right text-sm;
  margin: -4px 0px;
}

.yb-cpu-stat-id {
  @apply text-right text-xs font-mono leading-4 whitespace-nowrap;
}

</style>
