<template>
  <div class="qp-tr" :node-id="o.id">
    <div class="node-compact" hide-arrow>
      <yb-tooltip
        class="qp-node-compact"
        :node-id="o.id"
        :class="classes"
        :template="tooltip"
        :props="{ o, nodes }"
        @click="open(o)"
      >
        <div class="qp-stat-ratio" />
        <div class="qp-icon-small">
          <component :is="icon(o.icon)" />
        </div>
      </yb-tooltip>
    </div>
  </div>
</template>

<script>
import nodeMixin from './nodeMixin'
import iconsMixin from './icons/iconsMixin'
import tooltipMixin from './tooltipMixin'

export default {
  mixins: [nodeMixin, iconsMixin, tooltipMixin]
}
</script>
