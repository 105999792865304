<template>
  <div class="qp-tr" :node-id="o.id">
    <div class="node">
      <yb-tooltip
        class="qp-node"
        :node-id="o.id"
        :class="classes"
        :template="tooltip"
        :props="{ o, nodes }"
        @click="open(o)"
      >
        <div class="qp-stat-ratio" />
        <div v-if="o.stats && o.stats.rows && o.stats.rows.sum >= 1" class="qp-stat-rows">
          {{ humanizeNumber(o.stats.rows.sum, 0) }}&nbsp;<translate>rows&nbsp;</translate>
        </div>
        <div v-if="o.stats.io_spill_read_bytes || o.stats.io_spill_write_bytes" class="qp-stat-spill">
          <yb-icon class="yb-button-icon-md" icon="warning" />
        </div>
        <div v-if="o.stats.iterations" class="qp-stat-iterations">
          <div class="qp-stat-iterations-label">
            {{ o.stats.iterations }}&nbsp;<i class="fa fa-refresh" />
          </div>
        </div>
        <div class="qp-header">
          <div class="qp-header-icon">
            <div class="qp-icon">
              <component :is="icon(o.icon)" />
            </div>
          </div>
          <div class="qp-label">
            <div>
              <strong>{{ translate(o.nodeType) }}</strong>
            </div>
            <div v-if="o.explain">
              {{ o.explain }}
            </div>
            <div v-if="o.columnNames">
              ({{ o.columnNames.join(',') }})
            </div>
            <div v-if="o.expressions.length > 0" style="max-height: 70px">
              <div v-for="(expression, index) in o.expressions" :key="index">
                {{ expression }}<br>
              </div>
            </div>
            <button v-if="o.dbg" v-yb-clipboard:function="o.dbg" class="btn btn-xs btn-link yb-node-debug smc-icon-btn" :title="translate('Click to copy this node json data to the clipboard')">
              <i class="fa fa-fw fa-copy" />
            </button>
          </div>
        </div>
      </yb-tooltip>
    </div>
  </div>
</template>

<script>
import nodeMixin from './nodeMixin'
import iconsMixin from './icons/iconsMixin'
import tooltipMixin from './tooltipMixin'
import { humanizeNumber, translate } from '@/filters'

export default {
  mixins: [nodeMixin, iconsMixin, tooltipMixin],
  methods: {
    humanizeNumber,
    translate
  }
}
</script>
