import { app } from '@/services'

export function formatErrorMessage(error) {
  if (error && String(error.error).match(/:/)) {
    const parts = error.error.split(/:/)
    parts.splice(0, 1)
    error = parts.join(':')
  }
  return app.formatError(error)
}
